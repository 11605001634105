import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";
import { JwtTokenEndPointService } from '../../easylink-api-admin/jwt-token-end-point/jwt-token-end-point.service';
import { Accesstokenjwt } from '../../../model/Accesstokenjwt';

@Injectable({
  providedIn: 'root'
})
export class GeneratetokenService {
  
  constructor(private _JwtTokenEndPointService:JwtTokenEndPointService){}

  generatetoken(idPartner:string,userId:string,isbackofficepartner:boolean){
    let accesstokenjwt:Accesstokenjwt=new Accesstokenjwt()
    accesstokenjwt.isbackoffice=isbackofficepartner
    accesstokenjwt.partnerid=idPartner
    accesstokenjwt.userid=userId
    return this._JwtTokenEndPointService.GenerateJwtToken(accesstokenjwt)
  }
  decodeToken(jwt){
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(jwt);
 
    return decodedToken
  }


}
