import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment'
import { FunctionResponse } from '../../../model/response/FunctionResponse';
import {CheckPasswordResponse} from "../../../model/response/check-password-response";
import {User} from "../../../model/User";
import {OneUserResponse} from "../../../model/response/OneUserResponse";
import { AccesLogResponse } from '../../../model/response/AccesLogResponse';
import {UserResponse} from "../../../model/response/UserResponse";
import {PartnerResponse} from "../../../model/response/PartnerResponse";
import {UserDto} from "../../../model/dto/UserDto";
import {TypeUserResponse} from "../../../model/response/TypeUserResponse";
import {AffectationToProfilDto} from "../../../model/dto/affectation-to-profil-dto";
import {AffUserProfilsResponse} from "../../../model/response/aff-user-profils-response";
import {ProfilStatusResponse} from "../../../model/response/ProfilStatusResponse";
import { OneTypeUserResponse } from '../../../model/response/OneTypeUserResponse';
import {VusertypeuserpartnerResponse} from "../../../model/response/VusertypeuserpartnerResponse";
import { UserToPartner } from '../../../model/UserToPartner';
import { ModuleResponse } from '../../../model/response/ModuleResponse';
import { AccessRolesResponse } from '../../../model/response/AccessRolesResponse';
import { affectUserProviderDto } from '../../../model/dto/affectUserProviderDto';

@Injectable({
  providedIn: 'root'
})
export class UserEndPointService {
  constructor(private httpclient:HttpClient) { }
  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Authorization': 'Bearer 12345'}
  );
 httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer 12345'
  })
};
  functionByUser(iduser:string){
    return this.httpclient.get<FunctionResponse>(environment.backend_url+"functionByUser/"+iduser,{ headers: this.header })
  }
  getListAccessLog(){
    return this.httpclient.post<AccesLogResponse>(environment.backend_url+"getListAccesLog",{ headers: this.header })
  }
  checkUserPassword(userId:string, password:string){
    return this.httpclient.post<CheckPasswordResponse>(environment.backend_url + 'checkUserPassword/'+userId+'/'+password,  { headers: this.header });
  }
  updateUser(user:User,idUser:string){
    return this.httpclient.post<OneUserResponse>(environment.backend_url+"updateUser?idAdmin="+idUser,user, { headers: this.header });
  }
  getUserById(userId:string){
    return this.httpclient.post<OneUserResponse>(environment.backend_url+"getUserById/"+userId, { headers: this.header });
  }
  getUserList(){
    return this.httpclient.get<UserResponse>(environment.backend_url+'listUser',{ headers: this.header });
  }
  deleteUser(userId:string,adminId:string){
    return this.httpclient.delete<UserResponse>(environment.backend_url+'deleteUser/'+userId+'/'+adminId,{ headers: this.header });
  }
  getListTypeUser(){
    return this.httpclient.get<TypeUserResponse>(environment.backend_url+'ListTypUsers',{ headers: this.header });
  }
  createUser(user:User,idUser:string){
    return this.httpclient.post<OneUserResponse>(environment.backend_url+"createUser?idAdmin="+idUser,user, { headers: this.header });
  }
  affectUserToProfil(userProfils:AffectationToProfilDto){
    return this.httpclient.post<AffUserProfilsResponse>(environment.backend_url+"affectUserToProfil",userProfils, { headers: this.header });
  }
  getAdminStatusByUser(userId:string){
    return this.httpclient.post<ProfilStatusResponse>(environment.backend_url+"getADminStatusByUser/"+userId, { headers: this.header });
  }
  getPartnerStatusByUser(partnerId:string){
    return this.httpclient.post<ProfilStatusResponse>(environment.backend_url+"getPartnerStatusByUser/"+partnerId, { headers: this.header });
  }
  getUserBycodeTypeUserAndPartner(idPartner:string, CodeType:string){
    if(idPartner!=null)
    return this.httpclient.post<VusertypeuserpartnerResponse>(environment.backend_url+"getUserBycodeTypeUserAndPartner?idPartner="+idPartner+"&CodeType="+CodeType, { headers: this.header });
    else
      return this.httpclient.post<VusertypeuserpartnerResponse>(environment.backend_url+"getUserBycodeTypeUserAndPartner?CodeType="+CodeType, { headers: this.header });

  }
  getTypUserByCode(code:string){
    return this.httpclient.post<OneTypeUserResponse>(environment.backend_url+"getTypUserByCode/"+code, { headers: this.header });
  }

  affectUserToPartner(userToPartner:UserToPartner){
    return this.httpclient.post<any>(environment.backend_url+"affectUserToPartner",userToPartner, { headers: this.header });
  }
  updateaffectUserToPartner(userToPartner:UserToPartner){
    return this.httpclient.post<any>(environment.backend_url+"updateaffectUserToPartenaire",userToPartner, { headers: this.header });
  }

  sendmail(email:string){
    return this.httpclient.post<UserResponse>(environment.backend_url+"SendEmailToResetPassword/"+email, { headers: this.header });
  }
  editPassword(){
    return this.httpclient.post<UserResponse>(environment.backend_url+"EditPassword", { headers: this.header });
  }
  checklogin(login:string){
    return this.httpclient.post<Boolean>(environment.backend_url+"checklogin",login, { headers: this.header });
  }
  checkEmail(email:string){
    return this.httpclient.post<UserResponse>(environment.backend_url+"checkEmail",email, { headers: this.header });
  }
  getAccessRulesByCodeFoncAndIdUser(code:string,iduser:string){
    return this.httpclient.post<AccessRolesResponse>(environment.backend_url+"getAccessRulesByCodeFoncAndIdUser/"+code+"/"+iduser, { headers: this.header });
  }
  getUserByTypeAndPartnerId(partnerId:string){
    return this.httpclient.post<VusertypeuserpartnerResponse>(environment.backend_url+"getUserBycodeTypeUserAndPartner?idPartner="+partnerId+"&CodeType=USER_EL_PARTNER",null,{ headers: this.header })
  }

  getUserByProfil(idprofil:string){
    return this.httpclient.post<UserResponse>(environment.backend_url+"getUserByProfil/"+idprofil,null,{ headers: this.header })

  }
  affectUserToProvider(UserToProvider:affectUserProviderDto){
    return this.httpclient.post<any>(environment.backend_url+"affectUserToProvider",UserToProvider, { headers: this.header });

  }
  getUserProviderById(userproviderId:string){
    return this.httpclient.post<any>(environment.backend_url+"getVUserProviderById/"+userproviderId, { headers: this.header });
  }

  getVUserProviderById(iduser:string,idprovider:string){
    return this.httpclient.post<any>(environment.backend_url+"getVUserProviderById/"+iduser+"/"+idprovider, { headers: this.header });
  }

  findAllByIdUserAndProfilsFValidOperation(iduser:string){
    return this.httpclient.post<any>(environment.backend_url+"findAllByIdUserAndProfilsFValidOperation/"+iduser, { headers: this.header });
  }
}
