import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PartnerTypeResponse } from '../../../model/response/PartnerTypeResponse';
import { UserCategory } from '../../../model/UserCategory';
import { Partner } from '../../../model/Partner';
import { OnePartnerResponse } from '../../../model/response/OnePartnerResponse';
import { VPartnerResponse } from '../../../model/response/VPartnerResponse';
import { PartnerResponse } from '../../../model/response/PartnerResponse';
import { ModuleResponse } from '../../../model/response/ModuleResponse';
import { PartnerProviderResponse } from '../../../model/response/PartnerProviderResponse';
import { PartenaireDto } from '../../../model/dto/PartenaireDto';


@Injectable({
  providedIn: 'root'
})
export class PartnerEndPointService {

  constructor(private httpclient:HttpClient) { }

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
); 

  getListPartnertyp(){
    return this.httpclient.post<PartnerTypeResponse>(environment.backend_url+"getListPartnertyp",{ headers: this.header });
  }
  affectUserCategorieToPartenaire(userCategory:UserCategory[],idpartner:string,iduser:string){
    return this.httpclient.post<PartnerTypeResponse>(environment.backend_url+'affectUserCategorieToPartenaire?idpartner='+idpartner+'&idUser='+iduser,userCategory,{ headers: this.header })
  }
  CreatePartner(partner:Partner,userId:string){
    return this.httpclient.post<OnePartnerResponse>(environment.backend_url+"CreatePartner?idUser="+userId,partner,{ headers: this.header })
  }
  getVPartner(){
    return this.httpclient.post<VPartnerResponse>(environment.backend_url+"getVPartner",{ headers: this.header })
  }

  getListPartnerId(partnerId:string){
    return this.httpclient.post<OnePartnerResponse>(environment.backend_url+"getPartnerById/"+partnerId,{ headers: this.header });
  }
  getListPartnerByUser(userId:string){
    return this.httpclient.post<PartnerResponse>(environment.backend_url+"getPartnersByUserId/"+userId,{ headers: this.header });
  }

  updatePartner(partner:Partner,userId:string){
    return this.httpclient.put<any>(environment.backend_url+"updatePartner?idUser="+userId,partner,{ headers: this.header });
  }

  updateParamPatner(idpartner:string,idparam:string,value){
    return this.httpclient.post<PartnerResponse>(environment.backend_url+"updateParamPatner/"+idpartner+"/"+idparam+"?value="+value,{ headers: this.header });
  }
  getmodulebypartner(idpartner:string){
    return this.httpclient.post<ModuleResponse>(environment.backend_url+"getmodulebypartner/"+idpartner,{ headers: this.header })
  }
  generatedatafordemo(idpartner:string){
    return this.httpclient.post<any>(environment.backend_url+"generatedatafordemo/"+idpartner,{ headers: this.header })
  }
  getPartnerbyidprovider(providerId:string){
    return this.httpclient.post<PartnerProviderResponse>(environment.backend_url+'getpartnerbyidprovider/'+providerId,{ headers: this.header });
  }
  createPartnerwizard(partenaireDto:PartenaireDto){
    return this.httpclient.post<any>(environment.backend_url+"createPartnerwizard",partenaireDto,{ headers: this.header })
  }
  getUserCatById(idUserCategory:string){
    return this.httpclient.post<any>(environment.backend_url+"getUserCatById/"+idUserCategory,{ headers: this.header })
  }
  getListPartner(){
    return this.httpclient.post<PartnerProviderResponse>(environment.backend_url+'getListPartner',{ headers: this.header });
  }
  checkAdherentAffedtedToPartner(partnerID:string){
    return this.httpclient.get<PartnerProviderResponse>(environment.backend_url+'CheckAdherentPartner/'+partnerID,{ headers: this.header });
  }
}
