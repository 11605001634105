import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatComponent } from './chat.component';
import { NbChatModule, NbListModule, NbCardModule, NbUserModule, NbTooltipModule } from '@nebular/theme';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';


@NgModule({
  declarations: [ChatComponent],
  imports: [
    NbChatModule,
    CommonModule,
    NbListModule,
    NbCardModule,
    NbUserModule,
    NbTooltipModule,
    FormsModule,
    Ng2SearchPipeModule,
    TranslateModule.forRoot({
      // loader: {
      //   provide: TranslateLoader,
      //   useFactory: HttpLoaderFactory,
      //   deps: [HttpClient]
      // }
    }),
  ],
  exports:[
    ChatComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ChatModule { }
