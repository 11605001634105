import { User } from './User';

export class Conversation {
    dateCreation : Date;
    idPartner : string;
    idUserCreated : string;
    idUserReceiver : string;
    idmsgConversation : string;
    msgConversation : string;
    status :Number;
    toAdmin : string;
    subject : string;
    type : string;
    userCreated : User;
}