import {TypUser} from "./typ-user";
import {Profil} from "./Profil";
import { Partner } from './Partner';
import { UserProvider } from './UserProvider';

export class User {
  idUser: string;
  iduser: string;
  matricule: string;
  email: string;
  firstname: string;
  lastname: string;
  firstnamear: string;
  lastnamear: string;
  login: string;
  password: string;
  gender: number;
  isactif: number;
  provisionalpwd:number;
  isdeleted:number;
  isvalidated:number;
  addeddate:Date;
  boVerified:number;
  bulkSms:number;
  mallStatus:number;
  newsletter:number;
  pushNotification:number;
  mealticket:number;
  birthday:Date;
  typUser:TypUser;
  phone1:string;
  phone2:string;
  job:string;
  jobar:string;
  cin:string;
  cinpath1:string;
  cinpath2:string;
  datecin:Date;
  governorate:string;
  city:string;
  zipcode:string;
  address:string;
  picture:string;
  blacklist:number;
  profils:Profil[];
  fullName:string;
  userProviders:UserProvider[];
  partner:Partner;
  Prénom:string;
  Date_de_Création:Date;
  Statut:string;
  NomPrénom:string;
  Type_utilisateur:string;
  centre:string;
  isvalidadh : number;
  constructor() {
  }
}
