import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationResponse } from '../../../model/response/NotificationResponse';
import { environment } from '../../../../environments/environment'
import { Notifications } from '../../../model/Notifications';
import { SocketClientService } from '../../socketClient/socket-client.service';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationDto } from '../../../model/dto/NotificationDto';

@Injectable({
  providedIn: 'root'
})
export class NotificationEndPointService {

  constructor(private httpclient:HttpClient) { }
  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Authorization': 'Bearer 12345'}
  );
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer 12345'
    })
  };
  getNotificationBystatusAndType(status:number,type:string){
    return this.httpclient.get<NotificationResponse>(environment.backend_url+"GetListNotificationByStatusAndType/"+status+"/"+type,{ headers: this.header })
  }
  getNotificationBystatus(status:number){
    return this.httpclient.get<NotificationResponse>(environment.backend_url+"GetListNotificationByStatus/"+status,{ headers: this.header })
  }
  getNotificationByType(type:string){
    return this.httpclient.get<NotificationResponse>(environment.backend_url+"GetListNotificationByType/"+type,{ headers: this.header })
  }
  createNotification(notification:Notifications){
    return this.httpclient.post<NotificationResponse>(environment.backend_url+"CreateNotification",notification,{ headers: this.header })
  }
  updateNotification(notification:Notifications){
    return this.httpclient.post<NotificationResponse>(environment.backend_url+"UpdateNotification",notification,{ headers: this.header })
  }
  deleteNotification(idNotification:string){
    return this.httpclient.delete<NotificationResponse>(environment.backend_url+"DeleteNotification/"+idNotification,{ headers: this.header })
  }
  /*
  idpartner : string; localstorage get idPartner
  idprovider: string;
  typenotif: reponse;
  idusercreate: string; qui a creer le fouurnisseur user d el partner localstorage get iduser
  iduservalidate: string; qui a valider le fouurnisseur user d el backoffice Notificaion userEl open
  */

 /*{
  "idpartner": localstorage get idPartner,
  "idprovider": "c200bad4-3f76-4fcc-a0d6-89148e151d8f",
  "idusercreate": qui a creer le fouurnisseur user d el partner localstorage get iduser
  "iduservalidate": "string",
  "title": "Demande de validation d'un fournisseur",
  "typenotif": "demande"
}*/
  addNotificationinWebSocket(notifDto :NotificationDto){
    return this.httpclient.post<NotificationResponse>(environment.backend_url+"addNotification",notifDto,{ headers: this.header })
  }
  editNotaddNotificationinWebSocket(idnotif:string,uo:string){
    return this.httpclient.post<NotificationResponse>(environment.backend_url+"editnotification/"+idnotif+"/"+uo,{ headers: this.header })
  }
  GetListNotificationByTypein(types:string[]){
    return this.httpclient.post<NotificationResponse>(environment.backend_url+"GetListNotificationByTypein",types,{ headers: this.header })
  }
  getAllByTypeNotificationInAndIdUserReceiver(typeuser:string,page:number,size:number,iduser:string,types:string[]){
    return this.httpclient.post<any>(environment.backend_url+"getAllByTypeNotificationInAndIdUserReceiver/"+typeuser+"/"+page+"/"+size+"/"+iduser,types,{ headers: this.header })
  }
}
