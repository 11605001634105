import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PartnerResponse } from '../../../model/response/PartnerResponse';
import { environment } from '../../../../environments/environment';
import {ProfilResponse} from "../../../model/response/ProfilResponse";
import { affecProfilDto } from '../../../model/dto/affecProfilDto';
import { Profil } from '../../../model/Profil';
import { OneProfilResponse } from '../../../model/response/OneProfilResponse';
import {User} from "../../../model/User";
import {FunctionResponse} from "../../../model/response/FunctionResponse";
import {FuncProfilResponse} from "../../../model/response/FuncProfilResponse";
import { ProfilDto } from '../../../model/dto/ProfilDto';

@Injectable({
  providedIn: 'root'
})
export class ProfilEndPointService {

  constructor(private httpclient:HttpClient) { }

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
);

  getListProfilsByPartn(partnId:string){
    if(partnId==null){
      return this.httpclient.post<ProfilResponse>(environment.backend_url + 'getProfilByPartner',  { headers: this.header });

    }else{
      return this.httpclient.post<ProfilResponse>(environment.backend_url + 'getProfilByPartner?idPartner='+partnId,  { headers: this.header });

    }
  }
  getListProfils(){
    return this.httpclient.get<ProfilResponse>(environment.backend_url+'listProfils',{ headers: this.header });
  }
  getListProfilsByUser(userId:string){
    return this.httpclient.post<ProfilResponse>(environment.backend_url+'getProfilByUser/'+userId,{ headers: this.header });
  }
  createProfil(profil:Profil,idUser:string){
    return this.httpclient.post<OneProfilResponse>(environment.backend_url+"createProfil?idAdmin="+idUser,profil,{ headers: this.header });
  }
  affectProfilToFunctions(affecProfilDto:affecProfilDto){
    return this.httpclient.post<OneProfilResponse>(environment.backend_url+'affectProfilToFunctions',affecProfilDto,{ headers: this.header });
  }
  deleteProfil(profilId:string,userId:string){
    return this.httpclient.delete<OneProfilResponse>(environment.backend_url+'DeleteProfil/'+profilId+'/'+userId,{ headers: this.header });
  }
  getProfilById(profilId:string){
    return this.httpclient.post<OneProfilResponse>(environment.backend_url+'getProfilById/'+profilId,{ headers: this.header });
  }
  updateProfil(profil:Profil,idUser:string){
    return this.httpclient.post<OneProfilResponse>(environment.backend_url+"updateProfil?idAdmin="+idUser,profil,{ headers: this.header });
  }
  getFunctionsByProfil(profilId:string){
    return this.httpclient.post<FuncProfilResponse>(environment.backend_url+'getFunctionsByProfil/'+profilId,{ headers: this.header });
  }

  createProfilDto(profil:ProfilDto,idUser:string){
    return this.httpclient.post<OneProfilResponse>(environment.backend_url+"createProfil?idAdmin="+idUser,profil,{ headers: this.header });
  }
  getProfilByPartner(partnerId:string){
    return this.httpclient.post<ProfilResponse>(environment.backend_url+'getProfilByPartner?idPartner='+partnerId,{ headers: this.header });
  }
  getActifprofil(){
    return this.httpclient.get<any>(environment.backend_url+'getActifprofil',{ headers: this.header });
  }

}
