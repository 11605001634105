import { Partner } from './../Partner';
export class Notification{
    dateCreation:Date;
    idNotification:string;
    idUserReceiver:string;
    partner:Partner;
    status:number;
    subject:string;
    title:string;
    typeNotification:string;
    userEl:any;
}