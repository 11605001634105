import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ProviderResponse } from '../../../model/response/ProviderResponse';
import { Provider } from '../../../model/Provider';
import { VProviderResponse } from '../../../model/response/VProviderResponse';
import { OneProviderResponse } from '../../../model/response/OneProviderResponse';
import { Contact } from '../../../model/Contact';
import { ContactResponse } from '../../../model/response/ContactResponse';
import { SecteurResponse } from '../../../model/response/SecteurResponse';
import { OneContactResponse } from '../../../model/response/OneContactResponse';
import { OneSecteurResponse } from '../../../model/response/OneSecteurResponse';
import { ProviderPartner } from '../../../model/ProviderPartner';
import { ProviderPartnerResponse } from '../../../model/response/ProviderPartnerResponse';
import { PartnerResponse } from '../../../model/response/PartnerResponse';
import { OneProviderPartnerResponse } from '../../../model/response/OneProviderPartnerResponse';
import { SearchValDto } from '../../../model/dto/SearchValDto';
@Injectable({
  providedIn: 'root'
})
export class ProviderEndPointService {
  constructor(private httpclient:HttpClient) { }

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
);


CreateProvider(provider:Provider,idUser:string,idCreator:string){
  return this.httpclient.post<VProviderResponse>(environment.backend_url_Provider+"createprovider?idUser="+idUser+'&idCreator='+idCreator,provider,{ headers: this.header })
}
UpdateProvider(provider:Provider,idUser:string,idCreator:string){
  return this.httpclient.post<VProviderResponse>(environment.backend_url_Provider+"updateprovider?idUser="+idUser+'&idCreator='+idCreator,provider,{ headers: this.header })
}

UpdateProviderPartner(provider:ProviderPartner,idCreator:string){
  return this.httpclient.post<any>(environment.backend_url_Provider+"updateproviderparnter?idCreator="+idCreator,provider,{ headers: this.header })
}
deleteProvider(providerId:string,idCreator:string){
  return this.httpclient.delete<OneProviderResponse>(environment.backend_url_Provider+"deleteprovider/"+providerId+"?idCreator="+idCreator,{ headers: this.header });
}

getProviderById(providerId:string){
  return this.httpclient.post<OneProviderResponse>(environment.backend_url_Provider+'getproviderbyid/'+providerId,{ headers: this.header });
}
CreateContact(contact:Contact[],idprovider:string,idCreator:string){
  return this.httpclient.post<ContactResponse>(environment.backend_url_Provider+"createContact?idprovider="+idprovider+'&idCreator='+idCreator,contact,{ headers: this.header })
}
getListContactbyProvider(providerId:string){
  return this.httpclient.post<ContactResponse>(environment.backend_url_Provider+'getcontactbyprovider/'+providerId,{ headers: this.header });
}
getlistofSector(){
  return this.httpclient.post<SecteurResponse>(environment.backend_url_Provider+'getActifSectorList',{ headers: this.header });
}
deleteContact(contactId:string,idCreator:string){
  return this.httpclient.delete<OneContactResponse>(environment.backend_url_Provider+"deletecontact/"+contactId+"?idCreator="+idCreator,{ headers: this.header });
}
getSectorByIdProvider(providerId:string){
  return this.httpclient.post<OneSecteurResponse>(environment.backend_url_Provider+'getsectorbyidprovider/'+providerId,{ headers: this.header });
}

getContactById(contactId:string){
  return this.httpclient.post<OneContactResponse>(environment.backend_url_Provider+'getcontactbyid/'+contactId,{ headers: this.header });
}
getproviderpartnerbyIdproviderandIdpartner(idProvider:string,idPartner:string){
  return this.httpclient.post<OneProviderPartnerResponse>(environment.backend_url_Provider+'getproviderpartnerbyidproviderandidparnter/'+idProvider+'/'+idPartner,null,{ headers: this.header });
}

UpdateContact(contact:Contact,providerId:string,idCreator:string){
  return this.httpclient.post<ContactResponse>(environment.backend_url_Provider+"updatecontact?idProvider="+providerId+'&idCreator='+idCreator,contact,{ headers: this.header })
}
getlistofproviderConnectedAndValidated(){
  return this.httpclient.post<any>(environment.backend_url_Provider+'getlistofproviderConnectedAndValidated',{ headers: this.header });
}
getprovidersListConnectedAndValidatedbyidpartenaire(idpartenaire:string){
  return this.httpclient.post<any>(environment.backend_url_Provider+'getprovidersListConnectedAndValidatedbyidpartenaire/'+idpartenaire,{ headers: this.header });
}
getprovidersListConnectedAndValidatedAndIsdeletedbyidpartenaire(idpartenaire:string){
  return this.httpclient.post<any>(environment.backend_url_Provider+'getprovidersListConnectedAndValidatedAndIsdeletedbyidpartenaire/'+idpartenaire,{ headers: this.header });
}
getVlistofproviderbypartner(searchValDto:SearchValDto){
  return this.httpclient.post<ProviderPartnerResponse>(environment.backend_url_Provider+"getVlistofproviderbypartner",searchValDto,{ headers: this.header });
}
getAllprovidersListConnectedAndValidated(){
  return this.httpclient.post<any>(environment.backend_url_Provider+'getAllprovidersListConnectedAndValidated',{ headers: this.header });
}
}