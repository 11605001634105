import { Injectable } from '@angular/core';
import {NbComponentStatus, NbToastrService} from "@nebular/theme";
import { UserEndPointService } from '../../easylink-api-admin/user-end-point/user-end-point.service';
import { User } from '../../../model/User';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  userconnected:User=new User()
  username:string=""
  types: NbComponentStatus[] = [
    'primary',
    'success',
    'info',
    'warning',
    'danger',
  ];

  title = 'Erreur!';
  content = `Veuillez!`;
  constructor(private toastrService: NbToastrService,private _UserEndPointService:UserEndPointService ) { }

  public showToast(type:NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      duration: 4000
    };
    const titleContent = title ? `${title}` : '';
    this.toastrService.show(
      body,
      `${titleContent}`,
      config,
    );
  } 
}