import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

//import * as SockJS from 'sockjs-client';
import { SocketClientState } from '../../model/SocketClientState';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SocketClientService {
  webSocketEndPoint: string = "https://bo.easylink.tn/easylink-api-admin/v1"
  
  //webSocketEndPoint: string = "http://localhost:8080/jsa-stomp-endpoint/v1"
  topic:string = "/topic/notification"
  topic2:string = "/topic/editnotification"
  stompClient: any;

  private state: BehaviorSubject<SocketClientState>;
  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT',
      'Authorization': 'Bearer 12345'}
  );
  httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer 12345'
    })
  };
  constructor() {
  }

  setConnected(connected: boolean) {
    this.disabled = !connected;

    if (connected) {
      this.greetings = [];
    }
  }
  greetings: any[] = [];
  disabled: boolean;
  _disconnect(){
    if(this.stompClient !== null){
      this.stompClient.disconnect();
    }
  }

  errorCallBack(error){
    setTimeout(() => {
      //this._connect
    }, 5000);
  }

  _send(message){
    this.stompClient.send("/notif",{}, JSON.stringify(message));
  }

  onMessageReceived(message){
  }
}