import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Conversation } from '../../model/Conversation';
import { ConversationService } from '../../service/conversation/conversation.service';
import { HttpHeaders } from '@angular/common/http';
import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { UserEndPointService } from '../../service/easylink-api-admin/user-end-point/user-end-point.service';
import { User } from '../../model/User';

import { LocalstorageService } from '../../service/GlobalService/Localstorage/localstorage.service';
import { Subject } from 'rxjs';
import { ConversationDto } from '../../model/dto/ConversationDto';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'ngx-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy{

  messages:any[]=[];
  users:any[]=[];
  showListUser:boolean=false;
  showChatUser:boolean=false;
  topic:string = "/topic/conversation";
  stompClient: any;
  conversations: any[] = [];
  greetings: any[] = [];
  disabled: boolean;
  searchterm : string;
  nbrallmessages : number = null;
  userResceiver : User;
  userToken :any [] = [];
  userConnected : User;
  messagesended : boolean = false;
  timetracker : number = 600000;
  timetrackerFunction : any;
  lang:string;
  partnerId:string = localStorage.getItem("partnerid");
  private destroy$: Subject<void> = new Subject<void>();
  
  constructor(private _ConversationService : ConversationService, private _UserEndPointService : UserEndPointService,
    private _LocalstorageService : LocalstorageService,private translate : TranslateService) 
  {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang!=null){
        this.lang= event.lang;
        localStorage.setItem("lang", this.lang);
      }
    });

    if(localStorage.getItem("lang")!=null){
      this.lang=localStorage.getItem("lang");
      this.translate.use(this.lang);
    }else{
      this.lang="fr";
      localStorage.setItem("lang","fr");
      this.translate.use(this.lang);
    } 
  }
  typeUser : string;
  ngOnInit() {
    const socket = new SockJS('http://135.125.31.221:8080/easylink-api-admin/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});

    //const socket = new SockJS('http://164.132.181.114:8080/easylink-api-admin/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
    //const socket = new SockJS('http://localhost:8080/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
    
    this.stompClient = Stomp.over(socket);
    const _this = this;
    this.stompClient.connect({}, function (frame) {
      _this.setConnected(true);
      _this.stompClient.subscribe('/topic/conversation',(hello)=> {
        if(hello.body!=null){
        _this.addmsgtoConversation(JSON.parse(hello.body));
        }
      });
      _this.stompClient.subscribe('/topic/openconversation',(hello)=> {
        if(hello.body!=null){
          _this.openConversation(JSON.parse(hello.body));
        }
      });
      _this.stompClient.subscribe('/topic/takeconversation',(hello)=> {
        if(hello.body!=null){
          _this.takeConversation(JSON.parse(hello.body));
        }
      });
      _this.stompClient.subscribe('/topic/liberateconversation',(hello)=> {
        if(hello.body!=null){
          _this.liberateConversation(JSON.parse(hello.body));
        }
      });
      
    });
    this.getUserById(localStorage.getItem("UserId"));

    this._LocalstorageService.currentConversation.subscribe(res=>{
      if(res === "close"){
        this.closeConversation();
      }
    })
  }
  
  setConnected(connected: boolean) {
    this.disabled = !connected;
    if (connected) {
      this.greetings = [];
    }
  }

  addmsgtoConversation(newMessage){
    clearTimeout(this.timetrackerFunction);
    if(newMessage.userReceiver == null ){
      this.nbrallmessages = this.nbrallmessages +1;
    }
    if(newMessage.userCreated!=null && newMessage.userCreated.typUser.codeTypUser != 'USER_EL_BACKOFFICE' 
    && newMessage.userCreated.typUser.codeTypUser != 'USER_EL_PARTNER'){
      let userexist = false;
      if(this.users!=null && this.users.length>0){
        this.users.forEach(element=>{
          if(newMessage.userCreated.idUser == element.idUser && newMessage.conversation.partner.idPartner == element.partner.idPartner){
            userexist = true;
            if(element.connectedUserName==null){
              if(element.numberMessage==null){
                element.numberMessage = 1;
              }else{
                element.numberMessage ++ ;
              }
            }
            this.timetrackerFunction = setTimeout(() => {
              if(!this.messagesended){
                this.closeConversation();
              }
            },this.timetracker);
          }
        })
  
        if(!userexist){
          if(this.typeUser === "USER_EL_BACKOFFICE" ){
            newMessage.userCreated.partner = newMessage.conversation.partner;
            newMessage.userCreated.numberMessage = 1;
            this.users.unshift(newMessage.userCreated);
          }else if(this.typeUser === "USER_EL_PARTNER" && (this.partnerId!=null && newMessage.conversation.partner.idPartner == this.partnerId)){
            newMessage.userCreated.partner = newMessage.conversation.partner;
            newMessage.userCreated.numberMessage = 1;
            this.users.unshift(newMessage.userCreated);
          }
          // let user : any = {
          //   idUser : newMessage.userCreated.idUser,
          //   lastname : newMessage.userCreated.lastname,
          //   firstname : newMessage.userCreated.firstname,
          //   nbrmessage : 1,
          // }
        }
      }else{
        if(this.typeUser == "USER_EL_BACKOFFICE"){
          newMessage.userCreated.partner = newMessage.conversation.partner;
          newMessage.userCreated.numberMessage = 1;
          this.users.unshift(newMessage.userCreated);
        }else if(this.typeUser == "USER_EL_PARTNER" && newMessage.conversation.partner.idPartner == this.partnerId){
          newMessage.userCreated.partner = newMessage.conversation.partner;
          newMessage.userCreated.numberMessage = 1;
          this.users.unshift(newMessage.userCreated);
        }
      }
    }
    
    if(newMessage!=null && (this.conversations.length>0 && newMessage.conversation.partner.idPartner == this.conversations[0].conversation.partner.idPartner) )
    // && 
    //   (
    //     (newMessage.userCreated.idUser == localStorage.getItem("UserId")
    //       ||(newMessage.userReceiver !=null && newMessage.userReceiver.idUser == this.userResceiver.idUser )
    //     )|| (this.userResceiver==null || (this.userResceiver!=null && newMessage.userCreated.idUser == this.userResceiver.idUser))
    //   ) 
    // )

    {
      this.conversations.push({
        subject: newMessage.subject,
        dateCreation: newMessage.dateCreation,
        type:'text',
        //files: files,
        userCreated: newMessage.userCreated,
        status : newMessage.status 
      });
    }
  }

  getUsersConversation(){
    if(this.typeUser == "USER_EL_BACKOFFICE"){
      let allnbrsmessage = 0 ;
      this._ConversationService.getUserswithConversationForAdmin().subscribe(res=>{
        if(res.notificationResponse != null){
          this.users = res.notificationResponse;
          for(let i = 0; i<this.users.length;i++){
            if(this.users[i].connectedUserName != null){
              this.users[i].occupedBy = this.translate.instant('Pris par ') + this.users[i].connectedUserName + '\n' 
            }
            if(this.users[i].partner != null){
              if(this.users[i].occupedBy!=null){
                if(this.lang == "ar"){
                  this.users[i].occupedBy = this.users[i].partner.abbreviation + this.users[i].occupedBy + this.translate.instant('partenaire: ')
                }else{
                  this.users[i].occupedBy = this.users[i].occupedBy + this.translate.instant('partenaire: ')+ this.users[i].partner.abbreviation
                }
              }else{
                if(this.lang == "ar"){
                  this.users[i].occupedBy =  this.users[i].partner.abbreviation + this.translate.instant('Partenaire: ')
                }else{
                  this.users[i].occupedBy = this.translate.instant('Partenaire: ')+ this.users[i].partner.abbreviation
                }
              }
            }
            
            allnbrsmessage = allnbrsmessage + Number(this.users[i].numberMessage);
            if(i == (this.users.length-1)){
              this.nbrallmessages = allnbrsmessage;
            }
            if(this.users[i].connectedUserName!=null && this.userConnected.idUser == this.users[i].connectedUserId){
              this.getOneConversationByUser(this.users[i]);
              this.showListUser = true;
            }
          }
        }
      });
    }else if(this.typeUser == "USER_EL_PARTNER" && this.partnerId!=null){
      let allnbrsmessage =0 ;
      this._ConversationService.getUserswithConversationByPartner(this.partnerId).subscribe(res=>{
        if(res.notificationResponse != null){
          this.users = res.notificationResponse;
          for(let i = 0; i<this.users.length;i++){
            if(this.users[i].connectedUserName != null){
              if(this.lang == "ar"){
                this.users[i].occupedBy = this.users[i].connectedUserName + this.translate.instant('Pris par ')+ '\n' 
              }else{
                this.users[i].occupedBy = this.translate.instant('Pris par ') + this.users[i].connectedUserName+ '\n' 
              }
            }
            allnbrsmessage = allnbrsmessage + Number(this.users[i].numberMessage);
            if(i == (this.users.length-1)){
              this.nbrallmessages = allnbrsmessage;
            }
            if(this.users[i].connectedUserName!=null && this.userConnected.idUser == this.users[i].connectedUserId){
              this.getOneConversationByUser(this.users[i]);
              this.showListUser = true;
            }
          }
        }
      });
    }
  }
  
  getOneConversationByUser(userAdherent){
    if(!this.showChatUser && (userAdherent.connectedUserName==null || userAdherent.connectedUserId == this.userConnected.idUser)){
      this.userResceiver = userAdherent;
      if(userAdherent!=null){
        let cconversationDto : ConversationDto =  new ConversationDto();
        cconversationDto.idUserPartner = this.userConnected.idUser;
        cconversationDto.iduseradherent = userAdherent.idUser;
        cconversationDto.idPartner = userAdherent.partner.idPartner;
        this._ConversationService.getOneConversationByUser(cconversationDto).subscribe(res=>{
          if(res.notificationResponse != null){
            this.conversations = res.notificationResponse;
            this.showChatUser=true;
            this.users.forEach(el=>{
              if(el.idUser == userAdherent.idUser){
                if(this.nbrallmessages - el.numberMessage == 0){
                  this.nbrallmessages = null;
                }else{
                  this.nbrallmessages = this.nbrallmessages - el.numberMessage;
                }
                el.numberMessage = null;
              }
            });
          }
        })
      }else{
        this.showChatUser = false;
      }
    }
  }
  
  getUserById(userId:string){
    this._UserEndPointService.getUserById(userId).subscribe(res=>{
      if(res.userResponse!=null){
        this.userConnected = res.userResponse
        if (this.userConnected.typUser.codeTypUser === 'USER_EL_BACKOFFICE'){
          this.typeUser = "USER_EL_BACKOFFICE"
          this.getUsersConversation();
        }else if(this.userConnected.typUser.codeTypUser === 'USER_EL_PARTNER'){
          this.typeUser = "USER_EL_PARTNER";
          this.getUsersConversation();
        }else if(this.userConnected.typUser.codeTypUser === 'USER_EL_FOURNISSEUR'){
          this.typeUser = "USER_EL_FOURNISSEUR";
        }
      }
    })
  }
  
  sendMessage(event: any) {
    clearTimeout(this.timetrackerFunction);
    //this.timetracker = this.timetracker + 5000;
    const files = !event.files ? [] : event.files.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });
    if(event!=null && event.message!=""){
      let conversationToSend :Conversation = new Conversation;
      
      conversationToSend.idPartner = this.userResceiver.partner.idPartner;
      conversationToSend.idUserCreated = this.userConnected.idUser;
      conversationToSend.idUserReceiver = this.userResceiver.idUser;
      conversationToSend.subject = event.message;
      conversationToSend.toAdmin = "toadherent";
      this._ConversationService.createConversation(conversationToSend).subscribe(res=>{
        this.timetrackerFunction = setTimeout(() => {
          this.closeConversation();
        }, this.timetracker);
        // this.conversations.push({
        //   subject: event.message,
        //   dateCreation: new Date(),
        //   type: files.length ? 'file' : 'text',
        //   //files: files,
        //   userCreated: this.userCreate,
        //   status : true 
        // });
      },error=>{
      })
    }
    
    // const botReply = this.chatShowcaseService.reply(event.message);
    // if (botReply) {
    //   setTimeout(() => { this.messages.push(botReply) }, 500);
    // }
    //écrivez un message ...
    
  }

  showlist(){
    this.showListUser = !this.showListUser;
    if(!this.showListUser){
      this.showChatUser = false;
      if(this.conversations!=null && this.conversations[0]!=null && this.conversations[0].conversation!=null){
        this._ConversationService.closeConversation(this.conversations[0].conversation.idConversation).subscribe(res=>{
        })
      }
      
    }else{
      this.getUsersConversation();
    }
  }

  openConversation(conversation : Conversation){
    if(conversation[0].userCreated.idUser!=null){
      this.users.forEach(element=>{
        if(conversation[0].userCreated.idUser == element.idUser){
          this.nbrallmessages = this.nbrallmessages -  Number(element.numberMessage);
          element.numberMessage = null;
        }
      })
    }
  }

  closeConversation() {
    if(this.conversations!=null && this.conversations.length>0){
      this.showChatUser = false;
      this._ConversationService.closeConversation(this.conversations[0].conversation.idConversation).subscribe(res=>{
      })
    }
  }

  liberateConversation(conversation){
    this.users.forEach(element=>{
      if(conversation.userAdherent.idUser == element.idUser && conversation.partner.idPartner == element.partner.idPartner){
        element.connectedUserName = null;
        element.connectedUserId = null;
        if(this.typeUser == "USER_EL_BACKOFFICE"){
          if(this.lang == "ar"){
            element.occupedBy = this.translate.instant('partenaire: ')+ element.partner.abbreviation
          }else{
            element.occupedBy = element.partner.abbreviation + this.translate.instant('partenaire: ')
          }
        }else if(this.typeUser == "USER_EL_PARTNER"){ 
          element.occupedBy = null;
        }
      }
    })
  }
  
  takeConversation(conversation){
    this.users.forEach(element=>{
      if(conversation[0].conversation.userAdherent.idUser == element.idUser && conversation[0].conversation.partner.idPartner == element.partner.idPartner){
        element.connectedUserName = conversation[0].conversation.occupation.firstname+" "+ conversation[0].conversation.occupation.lastname;
        element.connectedUserId = this.userConnected.idUser;
        element.nbrmessage = null;
        //element.occupedBy = this.translate.instant('Pris par ') + conversation[0].conversation.occupation.firstname + " " + conversation[0].conversation.occupation.lastname + '\n' + element.occupedBy
      }
    })

    this.timetrackerFunction = setTimeout(() => {
      this.closeConversation();
    },this.timetracker);
  }

  ngOnDestroy() {
    this.closeConversation();
  }
  
  @HostListener('window:pagehide', ['$event'])
  beforeunloadHandler(event) {
    this.closeConversation();
  }

}