import { Partner } from './Partner'
import { User } from './User'
import { SubjectNotificationDto } from './dto/SubjectNotificationDto';

export class Notifications {
  
    idNotification:string;
    dateCreation:Date;
    status:number;
    subject:string;
    subject2:SubjectNotificationDto;
    title:string;
    typeNotification:string;
    partner:Partner;
    userEl:User;
    duration: any;
} 