import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Conversation } from '../../model/Conversation';
import { ConversationDto } from '../../model/dto/ConversationDto';

@Injectable({
  providedIn: 'root'
})
export class ConversationService {

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
  );

  constructor(private httpclient:HttpClient) { }
  
  createConversation(conversation:Conversation){
    return this.httpclient.post<any>(environment.backend_url+"addMsgConversation",conversation,{ headers: this.header })
  }

  getUserswithConversationByPartner(idPartner : string){
    return this.httpclient.post<any>(environment.backend_url+"getUserswithConversationByPartner",idPartner,{ headers: this.header })
  }

  getOneConversationByUser(cconversationDto: ConversationDto){
    return this.httpclient.post<any>(environment.backend_url+"getConversationByUser",cconversationDto,{ headers: this.header })
  }

  closeConversation(conversationId:string){
    return this.httpclient.post<any>(environment.backend_url+"closeConversation",conversationId,{ headers: this.header })
  }

  getUserswithConversationForAdmin(){
    return this.httpclient.post<any>(environment.backend_url+"getUserswithConversationForAdmin",{ headers: this.header })
  }
}
