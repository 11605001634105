import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import {AuthentificationComponent} from "./authentification/authentification.component";
import { AuthGuardService } from './guards/auth-guard.service';
import { PagesComponent } from './pages/pages.component';
import { InscriptionComponent } from './inscription/inscription.component';

const routes: Routes = [

  {
    path: 'pages',
    loadChildren: './pages/pages.module#PagesModule',
      canActivate: [AuthGuardService],
  },
  {
    path: 'auth',
    // loadChildren: () => import('./authentification/authentification.module')
    //   .then(m => m.AuthentificationModule),
      loadChildren: './authentification/authentification.module#AuthentificationModule',
  },
  {
    path: 'inscription/:Id',
    component: InscriptionComponent
    //loadChildren: './inscription/pages.module#PagesModule',
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'auth/login' },
 
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
