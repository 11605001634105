import { Injectable } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LocalstorageService } from '../Localstorage/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  lang:string
  
  constructor(public translate:TranslateService , private _localStorage: LocalstorageService) {  
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang=event.lang;
    });
    if(localStorage.getItem("lang")!=null){
      this.lang=localStorage.getItem("lang")
      translate.use(this.lang);
    }else{
      this.lang="fr"
      localStorage.setItem("lang","fr");
      translate.use(this.lang);
    }
  }
  
  GetDate(date:Date){
    var heur:string="";
    let dates:Date=new Date(date);
    var timeZoneDifference = (dates.getTimezoneOffset() / 60) * -1;

    dates.setTime(dates.getTime() - ((timeZoneDifference * 60) * 60 * 1000));
    var minute:string=String(dates.getMinutes());
    if((dates.getHours())<10){
      heur="0"+(dates.getHours());
    }else{
      heur=String((dates.getHours()));
    }
    if(dates.getMinutes()<10){
      minute="0"+dates.getMinutes();
    }
    if((dates.getMonth()+1)<10){
      if((dates.getDate())<10){
          return dates.getFullYear()+'-0'+(dates.getMonth()+1)+'-0'+(dates.getDate()+' '+heur+':'+minute);
      }else{
        return dates.getFullYear()+'-0'+(dates.getMonth()+1)+'-'+(dates.getDate()+' '+heur+':'+minute);
      }
    }else{
      if((dates.getDate())<10){
        return dates.getFullYear()+'-'+(dates.getMonth()+1)+'-0'+(dates.getDate()+' '+heur+':'+minute);
      }else{
        return dates.getFullYear()+'-'+(dates.getMonth()+1)+'-'+(dates.getDate()+' '+heur+':'+minute);
      }
    }
  }
  deletezonehour(date:Date){
    var heur:string="";
    var dates = new Date(date);
    var timeZoneDifference = (dates.getTimezoneOffset() / 60) * -1;
    dates.setTime(dates.getTime() - ((timeZoneDifference * 60) * 60 * 1000));
    return dates
  }

  addzonehour(date:Date){
    var heur:string="";
    var dates = new Date(date);
    var timeZoneDifference = (dates.getTimezoneOffset() / 60) * -1;
    // dates.setTime(dates.getTime() - ((timeZoneDifference * 60) * 60 * 1000));
    dates.setHours(dates.getHours()+timeZoneDifference);
    return dates
  }

  GetDateDDMMYYYY(date:Date){
    var heur:string="";
    let dates:Date=new Date(date);
    /*Time Zone*/
    //var timeZoneDifference = (dates.getTimezoneOffset() / 60) * -1;
    //dates.setTime(dates.getTime() - ((timeZoneDifference * 60) * 60 * 1000));
      
    if((dates.getMonth()+1)<10){
      if((dates.getDate())<10){
        return dates.getFullYear()+'-0'+(dates.getMonth()+1)+'-0'+(dates.getDate());
      }else{
        return dates.getFullYear()+'-0'+(dates.getMonth()+1)+'-'+(dates.getDate());
      }
    }else{
      if((dates.getDate())<10){
        return dates.getFullYear()+'-'+(dates.getMonth()+1)+'-0'+(dates.getDate());
      }else{
        return dates.getFullYear()+'-'+(dates.getMonth()+1)+'-'+(dates.getDate());
      }
    }
  }

  GetDateDDMMYYYYHHMM(date:Date){
    var heur:string="";
    let dates:Date=new Date(date);
    var minute:string=String(dates.getMinutes());
    if((dates.getHours())<10){
      heur="0"+(dates.getHours());
    }else{
      heur=String((dates.getHours()));
    }
    if(dates.getMinutes()<10){
      minute="0"+dates.getMinutes();
    }
    if((dates.getMonth()+1)<10){
      if((dates.getDate())<10){
        return dates.getFullYear()+'-0'+(dates.getMonth()+1)+'-0'+(dates.getDate()+' '+heur+':'+minute);
      }else{
        return dates.getFullYear()+'-0'+(dates.getMonth()+1)+'-'+(dates.getDate()+' '+heur+':'+minute);
      }
    }else{
      if((dates.getDate())<10){
        return dates.getFullYear()+'-'+(dates.getMonth()+1)+'-0'+(dates.getDate()+' '+heur+':'+minute);
      }else{
        return dates.getFullYear()+'-'+(dates.getMonth()+1)+'-'+(dates.getDate()+' '+heur+':'+minute);
      }
    }
  }

  getDurationwithdate(date:Date,lang:string){
    this.translate.use(lang);
    let dates:Date=new Date(date);
    let duration : any;
    let currentDate : Date =  new Date();
    duration = (currentDate.getTime() - dates.getTime())/(1000*60*60*24*30); //mois
    if(duration<1){
      duration = (currentDate.getTime() - dates.getTime())/(1000*60*60*24*7); //semaine
      if(duration<1){
        duration = (currentDate.getTime() - dates.getTime())/(1000*60*60*24); //jour
        if(duration<1){
          duration = (currentDate.getTime() - dates.getTime())/(1000*60*60); //heure
          if(duration<1){
            duration = (currentDate.getTime() - dates.getTime())/(1000*60); //minute
            if(duration<1){
              duration = (currentDate.getTime() - dates.getTime())/(1000); //second
              return "second";
            }else{
              return Math.round(duration) + this.translate.instant("minute"); 
            }
          }else{
            return Math.round(duration) + this.translate.instant("heure"); 
          }
        }else{
          return Math.round(duration) + this.translate.instant("jour"); 
        }
      }else{
        return Math.round(duration) + this.translate.instant("semaine"); 
      }
    }else if (duration>12){
      return 'null';
    }else{
      return Math.round(duration) + this.translate.instant("mois");
    }
  }
  getsendedday(date:Date){
    let d:Date=new Date(date);
    let dd:Date=new Date();
    if((Math.floor((dd.getTime() - d.getTime()) / (1000 ))<60)){
      return this.translate.instant("à linstant");
    }else if((Math.floor((dd.getTime() - d.getTime()) / (1000 * 60))<60)){
      let dd:Date=new Date();
      // dd.setTime((new Date().getTime()-d.getTime()))
      return this.translate.instant("il y a")+" "+ (Math.floor((dd.getTime() - d.getTime()) / (1000 * 60 )))+ this.translate.instant("minute");
    }else if((Math.floor((dd.getTime() - d.getTime()) / (1000 * 60 * 60))<24)){
      let dd:Date=new Date();
      //dd.setTime((new Date().getTime()-d.getTime()))
      return this.translate.instant("il y a")+" " + (Math.floor((dd.getTime() - d.getTime()) / (1000 * 60 * 60))) + this.translate.instant("heure");
    }else{
      let dd:Date=new Date();
      // dd.setTime((new Date().getTime()-d.getTime()))
      return this.translate.instant("il y a")+" " + (Math.floor((dd.getTime() - d.getTime()) / (1000 * 60 * 60 * 24)))  + this.translate.instant("jour")
    }
  }
}
