import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CenterResponse} from "../../../model/response/CenterResponse";
import {environment} from "../../../../environments/environment";
import { FamilyResponse } from '../../../model/response/FamilyResponse';
import { Family } from '../../../model/Family';
import { OneFamilyResponse } from '../../../model/response/OneFamilyResponse';
import { Center } from '../../../model/center';
import { OneCenterResponse } from '../../../model/response/OneCenterResponse';


import { Observable } from 'rxjs'
import { switchMap, map, tap } from 'rxjs/operators';
import { FamilyDto } from '../../../model/dto/familyDto';

@Injectable({
  providedIn: 'root'
})
export class NmcAdhEndPointService {


  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
  );

  constructor(private httpclient:HttpClient) { }

  getListCenters(){
    return this.httpclient.post<CenterResponse>(environment.backend_url_Adh+'getListCenters',{ headers: this.header });
  }
  getListCentersByPartner(partnerId:string){
    return this.httpclient.post<CenterResponse>(environment.backend_url_Adh+'getListCentersByPartner/'+partnerId,{ headers: this.header });
  }
  getListFamilies(){
    return this.httpclient.post<FamilyResponse>(environment.backend_url_Adh+'getListFamilies',{ headers: this.header });
  }
  getListFamiliesByPartner(partnerId:string){
    return this.httpclient.post<FamilyResponse>(environment.backend_url_Adh+'getVListFamilyByidpartner/'+partnerId,{ headers: this.header });
  }
  CreateFamily(family:FamilyDto,idPartner:string){
    return this.httpclient.post<FamilyResponse>(environment.backend_url_Adh+"createFamily?idAdmin="+idPartner,family,{ headers: this.header })
  }
  deleteFamily(familyId:string,idPartner:string){
    return this.httpclient.delete<FamilyResponse>(environment.backend_url_Adh+"deleteFamily/"+familyId+"?idAdmin="+idPartner,{ headers: this.header });
  }
  updateFamily(family:FamilyDto,idPartner:string){
    return this.httpclient.put<FamilyResponse>(environment.backend_url_Adh+"updateFamily?idAdmin="+idPartner,family, { headers: this.header });
  }
  getFamilyById(familyid:string){
    return this.httpclient.post<OneFamilyResponse>(environment.backend_url_Adh+'getFamilyById/'+familyid,{ headers: this.header });
  }
  createCenter(centre:Center,idAdmin: string,idPartner: string){
    return this.httpclient.post<CenterResponse>(environment.backend_url_Adh+"createCenter?idAdmin="+idAdmin+"&idpartner="+idPartner,centre,{ headers: this.header })
  }
  getCenterById(centerid:string){
    return this.httpclient.post<OneCenterResponse>(environment.backend_url_Adh+'getCenterbyid/'+centerid,{ headers: this.header });
  }
  updateCenter(center:Center,idAdmin: string,idPartner:string){
    return this.httpclient.put<CenterResponse>(environment.backend_url_Adh+"updateCenter?idAdmin="+idAdmin+"&idPartner="+idPartner,center, { headers: this.header });
  }
  deleteCenter(centerId:string,userId: string,idPartner:string){
    return this.httpclient.delete<CenterResponse>(environment.backend_url_Adh+"deleteCenter/"+centerId+"/"+userId+"?idAdmin="+idPartner,{ headers: this.header });
  }

  createMultipleCenter(centers:Center[],idAdmin: string,idPartner:string){
    return this.httpclient.post<any>(environment.backend_url_Adh+"createMultipleCenter?idAdmin="+idAdmin+"&idpartner="+idPartner,centers,{ headers: this.header });
  }

}
