import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbComponentStatus } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { Accesstokenjwt } from '../model/Accesstokenjwt';
import { Center } from '../model/center';
import { City } from '../model/City';
import { Country } from '../model/Country';
import { Partner } from '../model/Partner';
import { TypUser } from '../model/typ-user';
import { User } from '../model/User';
import { AdherentEndPointService } from '../service/easylink-api-adherent/adherent-end-point/adherent-end-point.service';
import { NmcAdhEndPointService } from '../service/easylink-api-adherent/nmc-adh-end-point/nmc-adh-end-point.service';
import { NmcPartEndPointService } from '../service/easylink-api-admin/nmc-part-end-point/nmc-part-end-point.service';
import { ParamEndPointService } from '../service/easylink-api-admin/param-end-point/param-end-point.service';
import { PartnerEndPointService } from '../service/easylink-api-admin/partner-end-point/partner-end-point.service';
import { UserEndPointService } from '../service/easylink-api-admin/user-end-point/user-end-point.service';
import { GeneratetokenService } from '../service/GlobalService/generatetoken/generatetoken.service';
import { NotificationService } from '../service/GlobalService/notification/notificationservice.service';

@Component({
  selector: 'ngx-inscription',
  templateUrl: './inscription.component.html',
  styleUrls: ['./inscription.component.scss']
})
export class InscriptionComponent implements OnInit {

  constructor(private _PartnerEndPointService:PartnerEndPointService,private _FormBuilder:FormBuilder,
    private _NmcAdhEndPointService:NmcAdhEndPointService,private _ParamEndPointService:ParamEndPointService,
    private _NmcPartEndPointService:NmcPartEndPointService,private translate:TranslateService,
    private _ActivatedRoute: ActivatedRoute,private _UserService :UserEndPointService,
    private _GeneratetokenService : GeneratetokenService,private router: Router,
    private _AdherentEndPointService :AdherentEndPointService,
    private _NotificationService : NotificationService) { }
  nbSpinner: boolean = false;
  s: boolean = false;
  isSubmitted : boolean = false;
  creatememberForm:FormGroup;
  typUsers:TypUser[]=[];
  partnerId : string = this._ActivatedRoute.snapshot.paramMap.get('Id')
  public calendar: any;
  calendar_fr: any;
  calendar_en: any;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  genders=[{name:this.translate.instant("M"),value:'0'},{name:this.translate.instant("Mme"),value:'1'},{name:this.translate.instant("Mlle"),value:'2'}];

  ngOnInit() {
    console.log("Mahaaaaa")
    this.getPartnerbyid();
    this.calendar_fr = {
      closeText: "Fermer",
      prevText: "Précédent",
      nextText: "Suivant",
      currentText: "Aujourd'hui",
      monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin",
        "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
      monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
        "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
      dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
      dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
      dayNamesMin: [ "D","L","M","M","J","V","S" ],
      weekHeader: "Sem.",
      dateFormat: "dd-mm-yy",
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ""
    };
    this.calendar_en = {
      closeText: "مغلق",
      prevText: "سابق",
      nextText: "التالي",
      currentText: "اليوم",
      monthNames: [ "جانفي","فيفري","مارس","أفريل","ماي","جوان",
        "جويلية","أوت","سبتمبر","أكتوبر","نوفمبر","ديسمبر" ],
      monthNamesShort: [ "جانفي","فيفري","مارس","أفريل","ماي","جوان",
      "جويلية","أوت","سبتمبر","أكتوبر","نوفمبر","ديسمبر" ],
      dayNames: [ "الأحد", "الاثنين", "التلاتاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ],
      dayNamesShort: [ "الأحد", "الاثنين", "التلاتاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ],
      dayNamesMin: [ "الأحد", "الاثنين", "التلاتاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ],
      weekHeader: "Wk",
      dateFormat: "ع ع-ش ش -ي ي",
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: "" };
    this.calendar=this.calendar_fr
    this.getCountry();
    this.getListCentersByPartner(this.partnerId);
    this.getVparambyidpartneranddesignation(this.partnerId,'Chargement CIN adhérent');
    this.getVparambyidpartneranddesignation(this.partnerId,'Centres régionaux');
    this._UserService.getListTypeUser().subscribe(res =>{this.typUsers = res.partnerResponse.filter(value => value.codeTypUser == 'USER_EL_ADH');});


    this._UserService.getListTypeUser().subscribe(res =>{
      this.typUsers = res.partnerResponse.filter(value => value.codeTypUser == 'USER_EL_ADH');
    });
    this.creatememberForm = this._FormBuilder.group({
      firstname:['',[Validators.required,Validators.maxLength(50)]],
      firstnamear:['',[Validators.maxLength(50)]],
      lastname:['',[Validators.required,Validators.maxLength(50)]],
      lastnamear:['',[Validators.maxLength(50)]],
      gender:['',[Validators.required]],
      job:['',[Validators.maxLength(50)]],
      jobar:['',[Validators.maxLength(50)]],
      matricule:['',[Validators.required,Validators.maxLength(30)]],
      center:[''],
      birthday:['',[Validators.required]],
      email:['',[Validators.pattern('^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$')]],
      cin:['',[Validators.required]],
      phone1:['',[Validators.required]],
      phone2:[''],
      datecin:[''],
      governorate:[''],
      city:[''],
      country:[''],
      zipcode:[''],
      address:[''],

    });
    
  }
  
  partner:Partner = new Partner();
  getPartnerbyid(){
    this._PartnerEndPointService.getListPartnerId(this._ActivatedRoute.snapshot.paramMap.get('Id')).subscribe(res=>{
      if(res.partnerResponse){
        this.partner = res.partnerResponse
      }
    })
  }
  centers:Center[]=[];
  vide: boolean = false;
  getListCentersByPartner(partnerId:string){
    this._NmcAdhEndPointService.getListCentersByPartner(partnerId).subscribe(res =>{
      if(res.result == 0){
        this.centers = res.centerResponse;
        this.vide = false
        this.centers.forEach(val=>{
          val.code_name= val.code+'-'+val.name
        })
      }

      if((this.centers==null) || (this.centers!=null &&this.centers.length== 0)){
        this.vide = true
      }

    });
  }
  showCenters:boolean = false;
  getVparambyidpartneranddesignation(partnerId:string,nameParam:string){
    this._ParamEndPointService.getVparambyidpartneranddesignation(partnerId,nameParam).subscribe(res =>{
      if (res.result == 0){
        console.log(res.paramResponse[0].valueParam)
        if (nameParam == 'Centres régionaux'){
          console.log(res.paramResponse[0].valueParam == '1')
          this.showCenters = res.paramResponse[0].valueParam == '1';
        }
      }
    });
  }
  center: Center = null
  centerError:boolean = false;
  choosecenter(event){
    if(event){
      this.center = event
      this.creatememberForm.value.center = event;
    }else{
      this.creatememberForm.value.center = '';
      this.center = null;
    }
  }
  countrys: Country[] = [];
  citys: City[] = [];
  getCountry(){
    this._NmcPartEndPointService.getListcountries().subscribe(countryres=>{
      if(countryres.partnerResponse != null){
        this.countrys = countryres.partnerResponse;
      }
    })
  }
  getcitysByCountry(Country){
    this.creatememberForm.controls['city'].setValue(Country);
    this._NmcPartEndPointService.getCityByCountry(Country.idCountry).subscribe(countryres=>{
      if(countryres.result == 0){
        this.citys = countryres.partnerResponse;
      }
    })
  }
  getzipcodeByCity(city){
    
    this.creatememberForm.controls['governorate'].setValue(city);
    if(city.codeCity!=null){
      this.creatememberForm.controls['zipcode'].setValue(city.codeCity);
    }
  }

  get formControls() { return this.creatememberForm.controls; }
  matriculeError:boolean = false;
  cinInvalid:boolean = false;
  matriculeUnique:boolean = false;
  notifStatus: NbComponentStatus = 'danger';
  phoneExist: boolean = false;
  centerId : string = '';
  onCreate(){
    this.isSubmitted = true;
   
    if (this.creatememberForm.invalid){
      this.s = false;
      this.nbSpinner = false;

    }else {
      let member: User = this.creatememberForm.value;
      if(this.creatememberForm.value.phone1.internationalNumber!=null){
        member.phone1 = (this.creatememberForm.value.phone1.internationalNumber).split(" ").join("");
      }else{
        member.phone1 =  this.creatememberForm.value.phone1
      }
      member.birthday=new Date(this.creatememberForm.value.birthday+ 'UTC');
      member.datecin=new Date(this.creatememberForm.value.datecin+ 'UTC');
      member.boVerified = 0;
      //this.member.isactif=1;
      member.bulkSms = 0;
      member.mallStatus = 0;
      member.newsletter = 1;
      member.pushNotification = 1;
      member.mealticket = 0;
      member.isdeleted = 0;
      member.isactif = 1;
      member.blacklist =0;
  
      if (this.creatememberForm.value.phone2 != null){
        member.phone2 =  this.creatememberForm.value.phone2.internationalNumber;
      }
      if (this.creatememberForm.value.governorate != null){
        member.governorate = this.creatememberForm.value.governorate.designationCity;
      }
      if (this.creatememberForm.value.country != null){
        member.city = this.creatememberForm.value.country.designationCountry;
      }
      

      this.matriculeError = false;
      this.cinInvalid = false;
      this.matriculeUnique = false;
     
      if (this.showCenters){
        this.center == null ? this.centerError = true : this.centerError = false;this.centerId = this.center.idCenter;
      }
      if ( !this.centerError){
        this.nbSpinner = true;
        this.s = true;
        this.phoneExist = false;
        member.isvalidadh = 0;
        this._AdherentEndPointService.createadherentinscription(member,this.partnerId,this.centerId).subscribe(res =>{

          if (res.result == 1){
            
            return this.router.navigateByUrl("https://bo.easylink.tn/");
          }else if (res.result == -8){
            this.matriculeError = true;
            this.s = false;
            this.nbSpinner = false;
          }else if (res.result == -3){
            this.cinInvalid = true;
            this.s = false;
            this.nbSpinner = false;
          }else if (res.result == -13){
            this.matriculeUnique = true;
            this.s = false;
            this.nbSpinner = false;
          }else if (res.result == 6){
            this.s = false;
            this.nbSpinner = false;
            this.notifStatus = 'warning';
            this._NotificationService.showToast('success',this.translate.instant('Alerte!'),this.translate.instant('Gestion adherent.Adhérent existe déjà et affecté au partenaire'));
            setTimeout(x=>{
              return this.router.navigateByUrl("https://bo.easylink.tn/");
            },1000);
          }else if (res.result == -6){
            this.s = false;
            this.nbSpinner = false;
            this.notifStatus = 'warning';
            this._NotificationService.showToast(this.notifStatus,this.translate.instant('Alerte!'),this.translate.instant('Gestion adherent.Cet adhérent existe ainsi affecté à ce partenaire'));
          }else if (res.result == -25){
            this.s = false;
            this.nbSpinner = false;
            this.notifStatus = 'danger';
            this._NotificationService.showToast(this.notifStatus,this.translate.instant('Echoué!'),this.translate.instant(res.errorDescription));
          }else if (res.result == -20){
            this.s = false;
            this.nbSpinner = false;
            this.phoneExist = true;
          }else{
            this.s = false;
            this.nbSpinner = false;
            this.notifStatus = 'danger';
            this._NotificationService.showToast(this.notifStatus,this.translate.instant('Echoué!'),this.translate.instant('Erreurlorscreationadh'));
          }
        });
      }else{
        this.s = false;
        this.nbSpinner = false;
      }
    }
  }
  return(){ 
    return this.router.navigateByUrl("https://bo.easylink.tn/");
  }


}
