import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ParamResponse } from '../../../model/response/ParamResponse';
import { Param } from '../../../model/Param';
import { PartnerParams } from '../../../model/partnerParams';
import { VPartnerParamResponse } from '../../../model/response/VPartnerParamResponse';
import { VparamResponse } from '../../../model/response/VparamResponse';

@Injectable({
  providedIn: 'root'
})
export class ParamEndPointService {

  constructor(private httpclient:HttpClient) { }

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
);

getListParamByCodeParamNatAndCodePartnTyp(codeParamNat:string,codePartnTyp:string){
  return this.httpclient.post<ParamResponse>(environment.backend_url+"getListParamByCodeParamNatAndCodePartnTyp/"+codeParamNat+"/"+codePartnTyp,{ headers: this.header })
}
createParam(param:Param){
  return this.httpclient.post<any>(environment.backend_url+"createParam",param,{ headers: this.header })
}
affectParamPartner(idpartner:string,partnerParams:PartnerParams[]){
  return this.httpclient.post<any>(environment.backend_url+"affectParamPartner/"+idpartner,partnerParams,{ headers: this.header })

}
getVListParamByidPartner(idpartner:string,idModule:string,codeNature:string){
  return this.httpclient.post<VPartnerParamResponse>(environment.backend_url+"getVListParamByidPartner/"+idpartner+"?idModule="+idModule+"&codeNature="+codeNature,{ headers: this.header })

}
getvparambycodemoduleandcodeTypepartenaire(codemodule,codetypepartner){
  return this.httpclient.post<VparamResponse>(environment.backend_url+"getvparambycodemoduleandcodeTypepartenaire/"+codemodule+"/"+codetypepartner,{ headers: this.header })
}
getVListParambidpartnerandcodefoncnotnull(idpartner:string){
  return this.httpclient.post<VPartnerParamResponse>(environment.backend_url+"getVListParambidpartnerandcodefoncnotnull/"+idpartner,{ headers: this.header })
}
  getVparambyidpartneranddesignation(partnerId:string,nameParam:string){
    return this.httpclient.post<VPartnerParamResponse>(environment.backend_url+"getVparambyidpartneranddesignation/"+partnerId+"/"+nameParam,{ headers: this.header })
  }
}
