import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { UserResponse } from '../../../model/response/UserResponse';
import { environment } from '../../../../environments/environment';
import {OneUserResponse} from "../../../model/response/OneUserResponse";
import {User} from "../../../model/User";
import {OneUserCategoryResponse} from "../../../model/response/OneUserCategoryResponse";
import {OneCenterResponse} from "../../../model/response/OneCenterResponse";
import {id} from "@swimlane/ngx-charts/release/utils";
import {OneUserPartnerResponse} from "../../../model/response/OneUserPartnerResponse";
import { AdherenrDto } from '../../../model/dto/AdherenrDto';

@Injectable({
  providedIn: 'root'
})
export class AdherentEndPointService {

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
  );

  constructor(private httpclient:HttpClient) { }
  getListAdhrentByPartner(partnerId:string){
    return this.httpclient.get<UserResponse>(environment.backend_url_Adh+'getlistadherentbypartner/'+partnerId,{ headers: this.header });
  }
  createadherent(user:User,idPartner:string,idUser:string,idcategory:string,idcenter:string){
    if (idcenter != ''){
      return this.httpclient.post<OneUserResponse>(environment.backend_url_Adh+'createadherent?idPartner='+idPartner+'&idUser='+idUser+'&idcateg='+idcategory+'&idcenter='+idcenter,user,{ headers: this.header });
    }else {
      return this.httpclient.post<OneUserResponse>(environment.backend_url_Adh+'createadherent?idPartner='+idPartner+'&idUser='+idUser+'&idcateg='+idcategory,user,{ headers: this.header });
    }
  }
  updateadherentcategoryandisvalid(idPartner:string,idUser:string,idcategory:string,isvalidated:number,idUsercreated:string){
    
    return this.httpclient.post<OneUserResponse>(environment.backend_url_Adh+'updateadherentcategoryandisvalid?idPartner='+idPartner+'&idUser='+idUser+'&idcateg='+idcategory+'&isvalidated='+isvalidated,idUsercreated,{ headers: this.header });
    
  }
  createadherentinscription(user:User,idPartner:string,idcenter:string){
    if (idcenter != ''){
      return this.httpclient.post<OneUserResponse>(environment.backend_url_Adh+'createadherentinscription?idPartner='+idPartner+'&idcenter='+idcenter,user,{ headers: this.header });
    }else {
      return this.httpclient.post<OneUserResponse>(environment.backend_url_Adh+'createadherentinscription?idPartner='+idPartner,user,{ headers: this.header });
    }
  }
  deleteadherent(adherentId:string,idUser:string,idPartner:string){
    return this.httpclient.delete<OneUserResponse>(environment.backend_url_Adh+'deleteadherent/'+adherentId+'?idUser='+idUser+'&idPartner='+idPartner,{ headers: this.header });
  }
  deleteUserInscription(adherentId:string,idUser:string,idPartner:string){
    return this.httpclient.delete<OneUserResponse>(environment.backend_url_Adh+'deleteUserInscription/'+adherentId+'?idUser='+idUser+'&idPartner='+idPartner,{ headers: this.header });
  }
  getadherentbyid(adherentId:string){
    return this.httpclient.post<OneUserResponse>(environment.backend_url_Adh+'getadherentbyid/'+adherentId,null,{ headers: this.header });
  }
  getCateogryforadheren(adherentId:string,idPartner:string){
    return this.httpclient.post<OneUserCategoryResponse>(environment.backend_url_Adh+'getCateogryforadheren/'+adherentId+'/'+idPartner,null,{ headers: this.header });
  }
  getcenterforadheren(adherentId:string,idPartner:string){
    return this.httpclient.post<OneCenterResponse>(environment.backend_url_Adh+'getcenterforadheren/'+adherentId+'/'+idPartner,null,{ headers: this.header });
  }
  updateadherent(user:User,idPartner:string,idUser:string,idcategory:string,idcenter:string){
    if (idcenter != '') {
      return this.httpclient.put<OneUserResponse>(environment.backend_url_Adh+ 'updateadherent?idPartner=' + idPartner + '&idUser=' + idUser + '&idcateg=' + idcategory + '&idcenter=' + idcenter, user, {headers: this.header});
    }else {
      return this.httpclient.put<OneUserResponse>(environment.backend_url_Adh + 'updateadherent?idPartner=' + idPartner + '&idUser=' + idUser + '&idcateg=' + idcategory, user, {headers: this.header});
    }
  }
  getlistadherentactifbypartner(partnerId:string){
    return this.httpclient.post<UserResponse>(environment.backend_url_Adh+'getactifadherentbypartner/'+partnerId,{ headers: this.header });
  }
  getUserPartnerForAdherent(adherentId:string,idPartner:string){
    return this.httpclient.post<OneUserPartnerResponse>(environment.backend_url_Adh+'getUserPartnerForAdherent/'+adherentId+'/'+idPartner,null,{ headers: this.header });
  }

  createMultipleAdherent(AdherenrDto:AdherenrDto[]){
    return this.httpclient.post<any>(environment.backend_url_Adh+'createMultipleAdherent',AdherenrDto,{ headers: this.header });
  }

}
