import { City } from './City';
import { User } from './User';
import { PartenerTyp } from './PartenerTyp';
import { UserCategory } from './UserCategory';
import { Country } from './Country';
import { Company } from './Company';
import { ProviderPartner } from './ProviderPartner';

export class Partner {
    idPartner:string;
    abbreviation:string;
    address:string;
    companyname:string;
    companynamear:string;
    contractEndDate:Date;
    contractNoticeDate:number;
    contractStartDate:Date;
    electionDate:Date;
    country:Country;
    fdemoData:Number;
    fdemoInstance:Number;
    ftaxFree:Number;
    isactif:Number;
    logo:string;
    odooId:string;
    phone:String;
    rcs:String;
    sendsms:number;
    smsbalance:number;
    smssent:number;
    taxnumber:String;
    url:String;
    city:City;
    partenerTyp:PartenerTyp ;
    userEl:User;
    userCategories:UserCategory[];
    company:Company;
    providerPartners:ProviderPartner ;
    attente:boolean;
    mf: string;
  
    
}