import { NotificationService } from './../../../service/GlobalService/notification/notificationservice.service';
import { NotificationfullDataDto } from './../../../model/dto/NotificationfullDataDto';
import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LocalstorageService } from '../../../service/GlobalService/Localstorage/localstorage.service';
import { UserEndPointService } from '../../../service/easylink-api-admin/user-end-point/user-end-point.service';
import { User } from '../../../model/User';
import { Router } from '@angular/router';
import { PartnerEndPointService } from '../../../service/easylink-api-admin/partner-end-point/partner-end-point.service';
import { GeneratetokenService } from '../../../service/GlobalService/generatetoken/generatetoken.service';
import { Accesstokenjwt } from '../../../model/Accesstokenjwt';
import { Partner } from '../../../model/Partner';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import 'rxjs/add/operator/share'
import { NotificationEndPointService } from '../../../service/easylink-api-admin/notificationEndPoint/notification-end-point.service';
import { Notifications } from '../../../model/Notifications';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateService } from '../../../service/GlobalService/DateSevice/date.service';

import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { SocketClientService } from '../../../service/socketClient/socket-client.service';
import { HttpHeaders } from '@angular/common/http';
import { log } from 'util';
import {ProviderEndPointService} from "../../../service/easylink-api-provider/provider-end-point/provider-end-point.service";
import { ProfilEndPointService } from '../../../service/easylink-api-admin/profil-end-point/profil-end-point.service';
import { Provider } from '../../../model/Provider';
import { Notification } from '../../../model/dto/Notification';
import { NotificationSubject } from '../../../model/dto/NotificationSubject';
import { NotificationSubjecttransaction } from '../../../model/dto/NotificationSubjecttransaction';

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <div class="modal-header" [ngStyle]="{float: lang == 'ar' ? 'right':'left'}">
      <h4 class="modal-title">{{'Notification!' | translate}}</h4>
      <label type="button" class="close" aria-label="Fermer" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </label>
    </div>
    <div class="modal-body" [ngStyle]="{float: lang == 'ar' ? 'right':'left'}">
      <div class="row">
        <div class="col-sm-12" [ngStyle]="{direction: lang == 'ar' ? 'rtl':'ltr'}">
          <img [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="notification.notification.typeNotification != 'bienvenu' && notification.notification.partner!=null && (notification.notification.partner.logo==null || notification.notification.partner.logo=='')" 
            style="width: 60px;  display: inline-block;" src="asset/images/placeholder-notif.jpg" alt=""/>
          <img [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="notification.notification.partner!=null && (notification.notification.partner.logo!=null && notification.notification.partner.logo!='')" 
            style="width: 60px; display: inline-block; " [src]="notification.notification.partner.logo" alt=""/>
          <img [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="notification.notification.typeNotification=='transaction'"
            style="width: 60px; display: inline-block; " class="notification-img" src="../../../../assets/images/random.svg" alt="" />
          <p [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" style="display: inline-block;font-weight: 600;margin-top: 16px;">{{notification.notification.title | translate}}!</p>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12" [ngStyle]="{direction: lang == 'ar' ? 'rtl':'ltr'}"> 
          <p [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="typeUser == 'USER_EL_BACKOFFICE'  && notification.notification.typeNotification!='bienvenu'">{{'LePart' | translate}}<span style="font-weight: bold;" > {{notification.notification.partner!=null?notification.notification.partner.partenerTyp!=null?notification.notification.partner.partenerTyp.designationPartnTyp:'':''}}  {{notification.notification.partner!=null?notification.notification.partner.companyname:''}} </span>
            {{'vient de créer un nouveau fournisseur' | translate}} {{notification.subject!=null?notification.subject.providerrcs:''}}<br>
            {{'Priére de procéder a la validation' | translate}}. <a style="cursor:pointer" (click)="providervalidation(notification.subject.idprovider);activeModal.close('Close click')"><span style="color: #0055A0 !important;text-decoration: underline;cursor:pointer">{{'cliquer ici' | translate}}</span></a>
          </p> 

          <p [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="notification.notification.typeNotification=='bienvenu'" [ngStyle]="{direction: lang == 'ar' ? 'rtl':'ltr'}">
            {{'Bonjour' | translate}} <span style="font-weight: bold;">{{notification.subject!=null?notification.subject.firstname:''}} {{notification.subject!=null?notification.subject.lastname:''}} </span>,
            {{'Bienvenu sur notre plateforme Veganet' | translate}}
          </p>
          <p [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="(typeUser == 'USER_EL_PARTNER' ) && notification.notification.typeNotification != 'transaction' && notification.notification.typeNotification != 'bienvenu'"> {{'Le fournisseur' | translate}}
            <span style="font-weight: bold;">{{notification.subject!=null?notification.subject.providerrcs:''}}</span>  {{'créer par'| translate}} 
            <span style="font-weight: bold;">{{notification.subject.companyname!=null?notification.subject.companyname:''}} </span> 
            <span *ngIf="!notification.notification.title.startsWith('Rejet')" >{{'est validé par' | translate}} </span> <span *ngIf="notification.notification.title.startsWith('Rejet')" > {{'est rejete par' | translate}}</span>
            <span style="font-weight: bold;">{{notification.subject!=null?notification.subject.firstname:''}} </span>  {{'le' | translate}} 
              {{notification.notification.dateCreation | date:'dd-MM-yyyy'}} {{'à' |translate}} {{notification.notification.dateCreation | date:'HH:mm:ss'}}
            <br>
          </p> 
        
          <p [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="(typeUser == 'USER_EL_FOURNISSEUR') && notification.notification.typeNotification != 'transaction' && notification.notification.typeNotification != 'bienvenu'"> {{'La commande crée a partir du fournisseur' | translate}}
            <span style="font-weight: bold;">{{notification.subject!=null?notification.subject.providerrcs:''}} </span>   
            <span *ngIf="!notification.notification.title.startsWith('Rejet')" > {{'est validé par' | translate}} </span> <span *ngIf="notification.notification.title.startsWith('Rejet')" > {{'est rejete par' | translate}}</span>
            <span style="font-weight: bold;">{{notification.subject!=null?notification.subject.firstname:''}} </span>  {{'le' | translate}} 
            {{notification.notification.dateCreation | date:'dd-MM-yyyy'}} {{'à' |translate}} {{notification.notification.dateCreation | date:'HH:mm:ss'}}
            <br>
          </p> 
          
          <div [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" *ngIf="(typeUser == 'USER_EL_PARTNER' || typeUser == 'USER_EL_FOURNISSEUR')&& notification.notification.typeNotification == 'transaction' && notification.subject!=null">
            {{'Veuillez procede a la validation de la' | translate}} <a style="cursor: pointer;" (click)="transaction(notification.subject.idTransaction);activeModal.close('Close click')"  class="cursor"><span style="color: #0055A0 !important;text-decoration: underline;">{{'transaction' | translate}}</span></a>
            {{'de ladhérent'  | translate}} <span  style="color: #0055A0 !important"> {{notification.subject.adehrent!=null?notification.subject.adehrent.firstname:''}} {{notification.subject.adehrent!=null?notification.subject.adehrent.lastname:''}}</span>
            {{notification.dateCreation | date:'dd-MM-yyyy'}} {{'à' |translate}} {{notification.notification.dateCreation | date:'HH:mm:ss'}}
            <br>
          </div> 
          
          <p [ngStyle]="{float: lang == 'ar' ? 'right':'left'}" style="color: #4c4b4bbd;">{{'Notification créer le' | translate }} {{notification.notification.dateCreation | date:'dd-MM-yyyy'}} {{'à' |translate}} {{notification.notification.dateCreation | date:'HH:mm:ss'}} </p>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button   nbButton type="button" (click)="activeModal.close('Close click')" status="danger"  style="background-image: linear-gradient(to right,	#f45c44 , #db3853); border-color: white;float:left;border:unset" [shape]="'round'" >{{'fermer' | translate}}</button>
      <!-- <label type="button" class="btn btn-outline-dark" >{{'fermer' | translate}}</label> -->
    </div>
  `
})

export class NgbdModalContent {
  @Input() notification:any;
  @Input() typeUser:string;
  //@Input() typeUser:String;
  lang:string
  constructor(public activeModal: NgbActiveModal,private router: Router,public translate:TranslateService,private _LocalstorageService :LocalstorageService) {
    this.lang=localStorage.getItem("lang");
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang!=null){
        this.lang= event.lang;
      }
    })
  }
  transaction(id){
    if(sessionStorage.getItem("token")!=null){
      let token=sessionStorage.getItem("token");
      let fonc=sessionStorage.getItem("fonc");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("fonc");
      window.open("https://bo.easylink.tn/pages/Operation/Gestionoperation/Modifieroperation/"+id);
      sessionStorage.setItem("token",token);
      sessionStorage.setItem("fonc",fonc);
    }else{
      this._LocalstorageService.changeIdOperation(id);
      this.router.navigateByUrl("/pages/Operation/Gestionoperation/Modifieroperation/"+id);
    }
  }
  providervalidation(idprovider){
    if(sessionStorage.getItem("token")!=null){
      let token=sessionStorage.getItem("token");
      let fonc=sessionStorage.getItem("fonc");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("fonc");
      window.open("https://bo.easylink.tn/pages/Administration/GestionFournisseur/ModifierFournisseur/"+idprovider);
      sessionStorage.setItem("token",token);
      sessionStorage.setItem("fonc",fonc);
    }else{
      this._LocalstorageService.changeIdProvider(idprovider);
      this.router.navigateByUrl("/pages/Administration/GestionFournisseur/ModifierFournisseur/"+idprovider);
    }
  }
}
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private onSubject = new Subject<{ key: string, value: any }>();
  public changes = this.onSubject.asObservable().share();
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  typeUser:string;
  currentTheme = 'corporate';
  notificationsize:number=0;
  userconnected:User=new User();
  username:string="";
  partner:Partner=new Partner();
  partneraffiche:Boolean=false;
  displayopen1:boolean = false;
  notificationOpen :Notifications = new Notifications();
  style:string="right";
  allNotifs:Notifications[] = [];
  lang:string = localStorage.getItem("lang");
  enable:Boolean;
  provideraffiche:Boolean=false;
  provider:Provider=new Provider();
  displayDelete:boolean = false;
  //socketClientService : SocketClientService

  constructor(private sidebarService: NbSidebarService, private menuService: NbMenuService,private themeService: NbThemeService,
    private userService: UserData,private layoutService: LayoutService,private breakpointService: NbMediaBreakpointsService,
    private _Localstorage:LocalstorageService,private _UserEndPointService:UserEndPointService,
    private _PartnerEndPointService:PartnerEndPointService,private _GeneratetokenService:GeneratetokenService,
    private router: Router,public translate:TranslateService,private modalService: NgbModal,
    private socketClientService : SocketClientService,private _DateService:DateService,private _NotificationEndPointService: NotificationEndPointService,
    private _ProviderEndPointService:ProviderEndPointService,private _ProfilEndPointService:ProfilEndPointService,private _NotificationService:NotificationService
    )
  {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang!=null){
        this.lang= event.lang;
        localStorage.setItem("lang", this.lang);
        if(this.lang=='fr'){
          this.enable = false;
          this.style="left";
        }else{
          this.enable = true;
          this.style="right";
        }
      }
    });

    if(localStorage.getItem("lang")!=null){
      this.lang=localStorage.getItem("lang");
      translate.use(this.lang);
    }else{
      this.lang="fr"
      localStorage.setItem("lang","fr");
      translate.use(this.lang);
    }
  }

  userMenu = [ { title: "Profil"}, { title: "Se Déconnecter"} ];
  setConnected(connected: boolean) {
    this.disabled = !connected;
    if (connected) {
      this.greetings = [];
    }
  }
  greetings: any[] = [];
  disabled: boolean;
  topic:string = "/topic/notification"
  stompClient: any;
  notiftoshow:Notifications[]=[]

  ngOnInit() {
    this.getchangeduser();
    this.getNotification(localStorage.getItem("lang"));
    this._Localstorage.start();
    this._Localstorage.currentlang.subscribe(vall=>{
      // this.getNotification(vall);
    })
    if(sessionStorage.getItem("token")!=null){
      let accesstokenjwt :Accesstokenjwt=this._GeneratetokenService.decodeToken(sessionStorage.getItem("token")).Accesstoken
      this._PartnerEndPointService.getListPartnerId(accesstokenjwt.partnerid).subscribe(res=>{
        if(res.partnerResponse!=null){
          this.partner=res.partnerResponse
          this.partneraffiche=true
          if(this.lang == "fr"){
            this.userMenu = [ { title:'Profil'}, { title:'Contact' }, { title:'Se Déconnecter de la Session' }];
          }else{
            this.userMenu = [ { title:'الملف الشخصي'},{ title:'جهات الاتصال' }, { title:'قطع الاتصال بالجلسة' } ];
          }
        }
      })
    }
    if(localStorage.getItem("partnerid")!=null){
      this._PartnerEndPointService.getListPartnerId(localStorage.getItem("partnerid")).subscribe(res=>{
        if(res.partnerResponse!=null){
          this.partner=res.partnerResponse;
          this.partneraffiche=true;
        }
      })
    }

    if(localStorage.getItem("provider")!=null){
      this._ProviderEndPointService.getProviderById(localStorage.getItem("provider")).subscribe(res=>{
        if(res.providerResponse!=null){
          this.provider=res.providerResponse;
          this.partneraffiche=true;
        }
      })
    }

    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if(title=="Se Déconnecter" || title=="تسجيل الخروج" ){
          this._Localstorage.changeConversation("close");
          localStorage.clear();
          sessionStorage.clear();
          if(this.lang!= null && this.lang!='')
            localStorage.setItem("lang",this.lang)
          this.router.navigateByUrl('/auth/login');
        }else if(title=="Se Déconnecter de la Session" || title=="قطع الاتصال بالجلسة"){
          sessionStorage.clear();
          window.close()
        } else if(title=="Contact" || title=="جهات الاتصال"){
          this.displayDelete = true
        }
        else {
          this.router.navigateByUrl('/pages/Administration/profil');

        }

      });

      //const socket = new SockJS('http://164.132.181.114:8080/easylink-api-admin/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
      //const socket2 = new SockJS('http://164.132.181.114:8080/easylink-api-css/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
     
      const socket = new SockJS('http://135.125.31.221:8080/easylink-api-admin/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
      const socket2 = new SockJS('http://135.125.31.221:8080/easylink-api-css/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
     
      
      //const socket2 = new SockJS('http://localhost:8080/jsa-stomp-endpoint',null,{headers:new HttpHeaders({'Content-Type': 'application/json'})});
  
      this.stompClient = Stomp.over(socket);
      let stompClient2 = Stomp.over(socket2);
      const _this = this;
      this.stompClient.connect({}, function (frame) {
        _this.setConnected(true);
        _this.stompClient.subscribe('/topic/notification',  (hello)=> {
          if(hello.body!=null){
            _this.addNewNotificationToList(JSON.parse(hello.body));
          }
        });
  
        _this.stompClient.subscribe('/topic/editnotification', (hello)=> {
          if(hello.body!=null){
            _this.viewnotification(JSON.parse(hello.body));
          }
        });
      });
      stompClient2.connect({}, function (frame) {
        stompClient2.subscribe('/topic/importcss', (hello)=> {
          if(hello.body!=null){
            _this.ImportedCss(JSON.parse(hello.body));
          }
        });
      })
  }

  ImportedCss(message){
    if(message.iduser==localStorage.getItem("UserId")){
      if(message.status=="succes"){
        this._NotificationService.showToast('success',this.translate.instant('Succès!'),this.translate.instant('Importation avec succès'))
      }else{
        this._NotificationService.showToast('danger',this.translate.instant('echec'),this.translate.instant('Importation est échouée'))
      }
    }    
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this._Localstorage.stop();
    this._Localstorage.changeConversation("close");
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  getchangeduser(){
    this._Localstorage.currentuser.subscribe(val=>{
      if(val!=null && val.firstname!="")
      this.username=val.firstname+" "+val.lastname;
    })
  }

  changetoogle(){
    if(this.enable==true){
      localStorage.setItem("lang",'fr');
      sessionStorage.setItem("lang",'fr');
      this._Localstorage.changelang('fr');
      this.userMenu = [ { title: "Profil"}, { title:"Se Déconnecter"} ];
    }else{
      localStorage.setItem("lang",'ar');
      this._Localstorage.changelang('ar');
      sessionStorage.setItem("lang",'ar')
      this.userMenu = [ { title: "الملف الشخصي"}, { title:"تسجيل الخروج"} ];
    }
  }

  getSubject2Notification(subject2){
    if(subject2.idusercreate!=null){
      this._UserEndPointService.getUserById(subject2.idusercreate).subscribe(res=>{
        if(res.userResponse!=null){
          subject2.usercreate = res.userResponse;
        }
      });
    }
     if(subject2.iduservalidate!=null){
      this._UserEndPointService.getUserById(subject2.iduservalidate).subscribe(res=>{
        if(res.userResponse!=null){
          subject2.uservalidate = res.userResponse;
        }
      });
    }
     if(subject2.idprovider !=null){
      this._ProviderEndPointService.getProviderById(subject2.idprovider).subscribe(res=>{
        if(res.providerResponse!=null){
          subject2.provider = res.providerResponse;
        }
      });   
    }
     if(subject2.idadherent !=null){
      this._UserEndPointService.getUserById(subject2.idadherent).subscribe(res=>{
        if(res.userResponse!=null){
          subject2.adherent = res.userResponse;
        }
      });
    }
    return subject2;
  }

  getNotificationByType(type:string ){
    this._NotificationEndPointService.getNotificationByType(type).subscribe(res=>{
      let notifSize :number = 0;
      if(res.notificationResponse!=null){
        this.allNotifs =  res.notificationResponse;
        this.allNotifs.forEach(val=>{
          val.subject2 = JSON.parse(val.subject);
          val.subject2 = this.getSubject2Notification(val.subject2);
          val.dateCreation = this._DateService.deletezonehour(val.dateCreation);
          val.duration = this._DateService.getDurationwithdate(val.dateCreation,this.lang);
          if(val.status == 0){
            notifSize=this.allNotifs.filter(value => value.status == 0).length;
          }
        })
        this.allNotifs.sort((a:Notifications,b:Notifications)=>new Date(b.dateCreation).getTime()-new Date(a.dateCreation).getTime())
        if(type == "reponse"){
          this.allNotifs = this.allNotifs.filter(val=>val.subject2.idusercreate == localStorage.getItem("UserId"));
          this.allNotifs.forEach(notiresponse=>{
            if(notiresponse.status == 0){
              this.notificationsize = this.allNotifs.filter(value => value.status == 0).length;
            }
          })          
        }else{
          this.notificationsize = notifSize;
        }
      }
    })
  }
  getNotificationByTypes(type:string[] ){
    this._NotificationEndPointService.GetListNotificationByTypein(type).subscribe(res=>{      
      let notifSize :number;
      if(res.notificationResponse!=null){
        this.allNotifs = res.notificationResponse;
        this.allNotifs.forEach(val=>{
          val.subject2 = JSON.parse(val.subject);
          val.subject2 = this.getSubject2Notification(val.subject2);
          val.dateCreation = this._DateService.deletezonehour(val.dateCreation);
          val.duration = this._DateService.getDurationwithdate(val.dateCreation,this.lang);
          if(val.status == 0){
            notifSize=this.allNotifs.filter(value => value.status == 0).length;
          }
        })
        this.notificationsize = notifSize;
        this.allNotifs.sort((a:Notifications,b:Notifications)=>new Date(b.dateCreation).getTime()-new Date(a.dateCreation).getTime())
        let t:boolean=false;
        for(let i=0;i<type.length;i++){
          if(type[i]=="reponse"){
            t=true;
          }
        }
        this.allNotifs = this.allNotifs.filter(val=>{
          if(val.typeNotification=="bienvenu"){      
            if(val.subject2.iduservalidate == localStorage.getItem("UserId")){
              return true;
            }else{
              return false;
            }
          }else{
            return true;
          }
          
        })
        if(t){
          this.allNotifs = this.allNotifs.filter(val=>val.subject2.idusercreate == localStorage.getItem("UserId"))
          this.allNotifs.forEach(notiresponse=>{
            if(notiresponse.status == 0){
              this.notificationsize = this.allNotifs.filter(value => value.status == 0).length;
            }
          })
        }
        
      }else{
        this.notificationsize = notifSize;
      }
    })
  }
  getnotifnbr(allNotifs){
    let nbr:number=0
    allNotifs.forEach(notiresponse=>{
      if(notiresponse.status == 0){
        nbr = allNotifs.filter(value => value.status == 0).length;
      }
    })
    this.notificationsize=nbr;
    return nbr;
  }
  open(notif) {
    const modalRef = this.modalService.open(NgbdModalContent);
    modalRef.componentInstance.notification = notif;
    modalRef.componentInstance.typeUser = this.typeUser;
    this.displayopen1 = true;
    this.notificationOpen = notif.notification;
    if(this.notificationOpen.idNotification!=null && this.notificationOpen.status == 0){
      this._NotificationEndPointService.editNotaddNotificationinWebSocket(this.notificationOpen.idNotification,localStorage.getItem("UserId")).subscribe(res=>{
        this.notificationOpen.status = 1;
      })
    }
  }
 
  newNotification(message){
    this.notificationsize++;
    this.notiftoshow.push(message);
    let notificationfullDataDto:NotificationfullDataDto= new NotificationfullDataDto();
    let notification:Notification=new Notification();
    notification.dateCreation=message.dateCreation;
    notification.idNotification=message.idNotification;
    notification.idUserReceiver=message.idUserReceive;
    notification.partner=message.partner;
    notification.status=message.status;
    notification.subject=message.subject;
    notification.title=message.title;
    notification.typeNotification=message.typeNotification;
    notification.userEl=message.userEl;
    
    notificationfullDataDto.notification=notification;
    if(message.typeNotification == "reponse" || message.typeNotification == "demande"){
      let notificationSubject:NotificationSubject=new NotificationSubject();
      notificationSubject.companyname=message.partner.companyname;
      notificationSubject.firstname=message.subject2.uservalidate!=null?message.subject2.uservalidate.firstname:'';
      notificationSubject.idpartner=message.partner.idPartner;
      notificationSubject.idprovider=message.subject2.idprovider;
      notificationSubject.iduser=message.subject2.idusercreate;
      notificationSubject.lastname=message.subject2.uservalidate!=null?message.subject2.uservalidate.lastname:'';
      notificationSubject.logo=message.partner.logo;
      notificationSubject.providerrcs=message.subject2.provider!=null?message.subject2.provider.rcs:'';
      notificationfullDataDto.subject = notificationSubject;
      this.notificationbyiduser.unshift(notificationfullDataDto);
    }else if(message.typeNotification == "transaction"){
      let notificationSubject:NotificationSubjecttransaction=new NotificationSubjecttransaction();
      notificationSubject.adehrent=message.subject2.adherent;
      notificationSubject.idTransaction=message.subject2.idadherent;
      notificationfullDataDto.subject=notificationSubject;
      this.notificationbyiduser.unshift(notificationfullDataDto);
    }
    else{
      this.notificationbyiduser.unshift(notificationfullDataDto);
    }
    this.allNotifs.push(message);
    this.allNotifs.sort((a:Notifications,b:Notifications)=>new Date(b.dateCreation).getTime()-new Date(a.dateCreation).getTime());
  }
  addNewNotificationToList(message) {
    message.subject2 = JSON.parse(message.subject);
    message.subject2 = this.getSubject2Notification(message.subject2);
    message.duration =this._DateService.getDurationwithdate(message.dateCreation,this.lang);
    //message.dateCreation = this._DateService.deletezonehour(message.dateCreation)
    if (this.userconnected.typUser.codeTypUser === 'USER_EL_BACKOFFICE' && (message.typeNotification == "demande"||(message.typeNotification == "bienvenu" && message.subject2.idusercreate == localStorage.getItem("UserId")))){
      this.newNotification(message);
    }else if((this.userconnected.typUser.codeTypUser === 'USER_EL_PARTNER' || this.userconnected.typUser.codeTypUser === 'USER_EL_FOURNISSEUR')  && (message.typeNotification == "reponse" ||message.typeNotification == "bienvenu") && message.subject2.idusercreate == localStorage.getItem("UserId")){
      this.newNotification(message);
    }else if((this.userconnected.typUser.codeTypUser === 'USER_EL_PARTNER'|| this.userconnected.typUser.codeTypUser === 'USER_EL_FOURNISSEUR')  && message.typeNotification == "transaction" ){
      if(message.subject2.idusercreate !=null){
        if(message.subject2.idusercreate == localStorage.getItem("UserId"))
          this.newNotification(message);
      }else{
        this._UserEndPointService.getUserByProfil(message.subject2.idrole).subscribe(val=>{
          val.userResponse.forEach(ele=>{
            if(message.subject2.idusercreate == ele.idUser){
              this.newNotification(message);
            }
          })  
        })
      }
    }
  }

  viewnotification(message){
    this.notiftoshow = this.notiftoshow.filter(val=> val.idNotification!=message.idNotification)
    //this.allNotifs = this.allNotifs.filter(val=> val.idNotification!=message.idNotification)
    this.allNotifs.forEach(val=>{
      if(val.idNotification==message.idNotification){
        val.status = 1;
      }
    })
    if (this.userconnected.typUser.codeTypUser === 'USER_EL_BACKOFFICE' && (message.typeNotification == "demande"  || message.typeNotification == "bienvenu")){
      this.notificationsize--;
    }else if((this.userconnected.typUser.codeTypUser === 'USER_EL_PARTNER'|| this.userconnected.typUser.codeTypUser === 'USER_EL_FOURNISSEUR') && (message.typeNotification == "reponse" || message.typeNotification == "bienvenu" || message.typeNotification ==  "transaction")){
      this.notificationsize--;
    }
  }

  hello: any;
  handleMessage(message){
    this.hello = message;
  }

  disconnect(){
    this.socketClientService._disconnect();
  }
  getNotification(vall){
    this._UserEndPointService.getUserById(localStorage.getItem("UserId")).subscribe(res=>{
      if(res.userResponse!=null){
        this.userconnected=res.userResponse;
        //affect username
        this.username=this.userconnected.firstname+" "+this.userconnected.lastname;
        if (this.userconnected.typUser.codeTypUser === 'USER_EL_BACKOFFICE'){
          this.typeUser = "USER_EL_BACKOFFICE";
          if(vall!="")
            this.lang=vall;
          this.findnotification("admin",0,20,["demande"]);
        }else if(this.userconnected.typUser.codeTypUser === 'USER_EL_PARTNER'){
          this.typeUser = "USER_EL_PARTNER";
          this.findnotification("notadmin",0,20,["reponse","transaction"]);
        }else if(this.userconnected.typUser.codeTypUser === 'USER_EL_FOURNISSEUR'){
          this.typeUser = "USER_EL_FOURNISSEUR";
          this.findnotification("provider",0,20,["reponse"]);
        }
      }
    })
  }
  notificationbyiduser:any[]=[]
  findnotification(typeuser:string,page:number,size:number,types:string[]){
    if(this.userconnected.typUser.codeTypUser === 'USER_EL_FOURNISSEUR') {
      this._NotificationEndPointService.getAllByTypeNotificationInAndIdUserReceiver(typeuser,page,size,localStorage.getItem("provider"),types).subscribe(val=>{
        if(val.notificationResponse!=null){
          this.notificationbyiduser=val.notificationResponse.notificationfullDataDto;
          this.notificationsize = val.notificationResponse.count>20? 20 : val.notificationResponse.count;
        }else{
          this.notificationbyiduser=[];
          this.notificationsize=0;
        }
      })
    }else{
      this._NotificationEndPointService.getAllByTypeNotificationInAndIdUserReceiver(typeuser,page,size,localStorage.getItem("UserId"),types).subscribe(val=>{
        if(val.notificationResponse!=null){
          this.notificationbyiduser=val.notificationResponse.notificationfullDataDto;
          this.notificationsize = val.notificationResponse.count>20? 20 : val.notificationResponse.count;
        }else{
          this.notificationbyiduser=[];
          this.notificationsize=0;
        }
      })
    }
  }

  getdate(date:Date){
   return this._DateService.getsendedday(date);
  }
}
