import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
      <span class="created-by"><b><a href="http://www.easylink.tn/" target="_blank">easylink</a></b> 2019 Version 1.0.3 Alpha </span>   
      <div >
      <span style="color :gray"> <b> Service Client :36 035 688 </b> || </span>
      <a   style="margin-left: 15px;" target="_blank" class="ion ion-social-facebook socials"(click)="openFacebook()"></a>
      <a  style="margin-left: 15px;" target="_blank" class="ion ion-social-linkedin socials" (click)="openLinkedIn()"></a>   
      </div>

  `,
})
export class FooterComponent {

  openFacebook(){
    window.open("https://www.facebook.com/VEGANET.tn/"); 
  }
  openLinkedIn(){
    window.open("https://www.linkedin.com/company/veganet"); 
  }
}
