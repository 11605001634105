import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CountryResponse } from '../../../model/response/CountryResponse';
import { CityResponse } from '../../../model/response/CityResponse';
import { UserCategory } from '../../../model/UserCategory';
import { UserCategoryResponse } from '../../../model/response/UserCategoryResponse'; 
import { Company } from '../../../model/Company';

@Injectable({
  providedIn: 'root'
})
export class NmcPartEndPointService {

  constructor(private httpclient:HttpClient) { }

  header = new HttpHeaders(
    {'Access-Control-Allow-Origin' : '*',
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT'}
);

  getListcountries(){
    return this.httpclient.get<CountryResponse>(environment.backend_url+"getListcountries",{ headers: this.header })
  }
  getCityByCountry(idCountry:string){
    return this.httpclient.post<CityResponse>(environment.backend_url+"getCityByCountry/"+idCountry,{ headers: this.header })
  }
  createUserCategory(usercategory:UserCategory,userid:string){
    return this.httpclient.post<any>(environment.backend_url+"createUserCategory?idAdmin="+userid,usercategory,{ headers: this.header })
  }
  updateUserCategory(usercategory:UserCategory,userid:string){
    return this.httpclient.post<any>(environment.backend_url+"updateUserCategory?idAdmin="+userid,usercategory,{ headers: this.header })
  }

  getListUserCategory(){
    return this.httpclient.post<any>(environment.backend_url+"getListUserCategory",{ headers: this.header })
  }
  getUsercategoryByPArtner(idPartner:string){
    return this.httpclient.get<any>(environment.backend_url+"getUsercategoryByPArtner/"+idPartner,{ headers: this.header })
  }
  getUserCatById(catid:string){
    return this.httpclient.post<any>(environment.backend_url+'getUserCatById/'+catid,{ headers: this.header });
  }
  deleteUserCat(idUsercat:string,idAdmin:string){
    return this.httpclient.post<any>(environment.backend_url+'deleteUserCat/'+idUsercat+"?idCreator="+idAdmin,{ headers: this.header });
  }
  getCountryByCode(codeCountry:string){
    return this.httpclient.get<any>(environment.backend_url+"getCoutnryByCode/"+codeCountry,{ headers: this.header })
  }
  createCompany(company:Company,userid:string){
    return this.httpclient.post<any>(environment.backend_url+"createCompany?idUser="+userid,company,{ headers: this.header })
  }

  updateCompany(company:Company,userid:string){
    return this.httpclient.post<any>(environment.backend_url+"updateCompany?idUser="+userid,company,{ headers: this.header })
  }
  getcompanybyid(compid:string){
    return this.httpclient.get<any>(environment.backend_url+'getcompanybyid/'+compid,{ headers: this.header });
  }
  deleteCompany(idCompany:string,idUser:string){
    return this.httpclient.delete<any>(environment.backend_url+'deleteCompany/'+idCompany+"?idUser="+idUser,{ headers: this.header });
  }
 
}
