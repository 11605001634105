import { User } from './User';
import { Contact } from './Contact';
import { Sector } from './Sector';

export class Provider {
    idProvider:string;
    addeddate:Date;
    address:string;
    custcode:string;
    deleteddate:Date;
    email:string;
    fax:string;
    isdeleted:number;
    logo:string;
    mf:string;
    mobile:string;
    name:string;
    phone:string;
    rcs:string;
    deletedby:string;
    website:string;
    isValidated:number;
    sector:Sector;
    isConnected:number;
    userEl:User;
    contacts:Contact[];
    idPartnerCreated:string;
    codes: string []; 
	txtAcceptSms:string;
	txtDeclineSms:string;
	sendAcceptSms:number;
	sendDeclineSms:number;
    
}
